import React, { useState, useEffect } from "react";
import { Offcanvas, Container, Form, Button, Row, Col } from "react-bootstrap";
import Cookies from "js-cookie";
import { useConsent } from "../../context/ConsentContext";
import CookieButton from "./CookieButton";

const labelsMap = {
	necessary: "Essenzielle (notwendig)",
	preferences: "Funktionale / Drittanbieter-Cookies",
};

const CookieConsentDrawer = () => {
	const { cookieSettings, updateCookieSettings } = useConsent();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const storedSettings = Cookies.get("cookieConsentSettings");
		if (!storedSettings) {
			setIsOpen(true);
		}
	}, []);

	const handleToggle = (category) => {
		const newSettings = { ...cookieSettings, [category]: !cookieSettings[category] };
		updateCookieSettings(newSettings);
	};

	const handleAcceptAll = () => {
		const allAccepted = {
			necessary: true,
			preferences: true,
			statistics: false, // Manipulated
			marketing: false, // Manipulated
		};
		updateCookieSettings(allAccepted);
		setIsOpen(false);
	};

	const handleDeclineAll = () => {
		const allDeclined = {
			necessary: true,
			preferences: false,
			statistics: false,
			marketing: false,
		};
		updateCookieSettings(allDeclined);
		setIsOpen(false);
	};

	const handleSave = () => {
		setIsOpen(false);
	};

	return (
		<>
			<Offcanvas show={isOpen} onHide={() => setIsOpen(false)} placement="bottom">
				<Container>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>Cookie-Einstellungen</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						{Object.entries(cookieSettings).map(([key, value]) =>
							key === "statistics" || key === "marketing" ? null : (
								<Form.Check
									type="switch"
									id={`custom-switch-${key}`}
                  className="custom-switch"
									label={labelsMap[key]}
									checked={value}
									onChange={() => handleToggle(key)}
									disabled={key === "necessary"}
									key={key}
								/>
							)
						)}
						<Row className="mt-3 justify-content-end">
							<Col xs="auto">
								<Button variant="outline-secondary" onClick={handleDeclineAll}>
									Alle ablehnen
								</Button>
							</Col>
							<Col xs="auto">
								<Button variant="outline-secondary" onClick={handleAcceptAll}>
									Alle akzeptieren
								</Button>
							</Col>
							<Col xs="auto">
								<Button variant="primary" onClick={handleSave}>
									Speichern
								</Button>
							</Col>
						</Row>
						<p className="mt-3" style={{ fontSize: 14}}>
							Weitere Informationen finden Sie in unserer <a href="/privacy">Datenschutzerklärung</a> und im <a href="/impressum">Impressum</a>.
						</p>
					</Offcanvas.Body>
				</Container>
			</Offcanvas>
			<CookieButton setOpen={() => setIsOpen(true)} />
		</>
	);
};

export default CookieConsentDrawer;
