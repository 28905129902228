import React from "react";
import { Container, Row, Col, } from "react-bootstrap";

const SocialMedia = () => {
  return (
    <div className="socialMedia-Background">
      <Container>
        <Row>
          <Col className="socialMedia-background-inside">
            <div
              style={{
                marginTop: "30px",
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              TÄGLICH NEUE INFO´S
            </div>
            <p 
              style={{
                textAlign:'center'
              }}
            >
              Folge mir auf INSTAGRAM oder trete meiner TELEGRAM-Gruppe bei um
              tägliche Info´s von mir mitzubekommen.
            </p>
          </Col>
        </Row>
        <ul className="social">
          <li>
            <a
              href="https://www.instagram.com/sein_im_licht/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://t.me/joinchat/ZYj_823v2wQxM2Qy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-telegram-plane"></i>
            </a>
          </li>
        </ul>
      </Container>
    </div>
  );
};

export default SocialMedia;
