import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const TelegramBanner = () => {

	return (
		<div className="telegram-banner-background">
			<section>
				<Container>
					<Row className="telegram-banner-background-inside">
						<Col>
							<div
								style={{
									marginTop: "30px",
									fontWeight: "bold",
									fontSize: "30px",
									textTransform: "uppercase",
								}}
							>
								Telegram-Gruppe "Heilsamer Raum"
							</div>
							<p>
								Sei herzlich Willkommen in unserer Telegram-Gruppe “Heilsamer
								Raum”. Hier findest du Impulse, Meditationen, Rituale, und
								Botschaften.
							</p>
							<a target="__blank" href="https://t.me/joinchat/ZYj_823v2wQxM2Qy">
								<Button>Anmelden</Button>
							</a>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
};

export default TelegramBanner;
