import React from "react";
import ReactDOM from "react-dom";
import "./bootstrap.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Privacy from "./screens/Privacy";
import Imprint from "./screens/Imprint";
import Legal from "./screens/Legal";
import ScrollToTop from "./components/ScrollToTop";
// import FeedRedirect from "./screens/FeedRedirect";
import { ConsentProvider } from "./context/ConsentContext";
import CookieConsentDrawer from "./components/CookieConsent/CookieConsentDrawer";

ReactDOM.render(
	<React.StrictMode>
		<ConsentProvider>
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<App />} />
					<Route path="/rechtliches" element={<Legal />} />
					<Route path="/impressum" element={<Imprint />} />
					<Route path="/privacy" element={<Privacy />} />
					{/* <Route path="/feed/podcast" element={<FeedRedirect />} /> */}
				</Routes>
				<ScrollToTop />
			</BrowserRouter>
			<CookieConsentDrawer />
		</ConsentProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
