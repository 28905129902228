import React, { useRef, useState } from "react";
import {
	Row,
	Col,
	Container,
	Button, ButtonGroup
} from "react-bootstrap";

// const data = [
// 	// {
// 	// 	key: 1,
// 	// 	content:
// 	// 		"Herzlich Willkommen, mein Name ist Daniela, mein Ursprungsname Shan'Adrana, Heilende Begleiterin. Meinen spirituellen Weg betrat ich mit 18 Jahren, als mich folgende Fragen beschäftigten: Wozu sind wir hier auf der Erde? Gibt es eine Seelenbestimmung? Was passiert mit der Seele nach dem Tod? Diese Fragen beschäftigten mich wahrscheinlich deswegen so früh, weil ich mit 12 Jahren meine Mutter verloren hatte und so mit dem Tod konfrontiert wurde. Mein erstes spirituelles Buch war daher 'das Buch vom Leben und Sterben' von Sogyal Rinpoche. Früh interessierte ich mich auf für alternative Heilungsmethoden. So reiste ich zu Teachings vom Dalai Lama, fing an zu meditieren und fand Lehrer/innen die mir meine Fragen beantworten konnten.",
// 	// },
// 	// {
// 	// 	key: 2,
// 	// 	content:
// 	// 		'Meine Reise in meine weibliche Kraft begann im August 2020 als ich ein Interview hörte während eines online Kongresses über die “Heilung deines Schoßraumes”. Es war von Claudia Schwarz mit Alicia Kusumitra. Und als Alicia ihre Geschichte erzählte, war ich zutiefst berührt in meinem Herzen. Sie ist Maya Priesterin und lebt in Guatemala.',
// 	// },
// 	// {
// 	// 	key: 3,
// 	// 	content:
// 	// 		"Nach schlaflosen Nächten beschloss ich mich, dem Ruf meines Herzens zu folgen und mich für die Einweihung zur Priesterin zu bewerben. Denn meine Weiblichkeit hatte tiefen Schmerz erfahren, ich fühlte mich nicht zuhause in meinem Körper. Ich litt unter starken Menstruationsschmerzen, starken Blutungen und Kopfschmerzen. So vieles hatte ich schon probiert. Homöopathie, Schüssler Salze, Kräuter, TCM-Mischungen und und und… alles half bisschen aber nicht vollständig. Ich wusste bei Alicia würde ich die Reise nach innen angehen, in meinen Schmerz hinein und ich spürte, dass ich so Heilung erfahren würde. Außerdem rief mich mein Herz, diesem Pfad zu folgen, denn ich erinnerte mich, dass ich in vergangenen Leben als Priesterin, Schamanin und Heilerin gewirkt hatte. Ich wollte diese Kräfte wieder in mir aktivieren, ans Licht bringen um den Menschen, vor allem den Frauen zu dienen. So dass auch sie Heilung erfahren um in ihre weibliche schöpferische Kraft zu kommen.",
// 	// },
// 	// {
// 	// 	key: 4,
// 	// 	content:
// 	// 		"So wurde ich eingeweiht zur Priesterin der weiblichen Kraft zusammen mit 9 anderen wundervollen Frauen in 9 Monaten. Ich wusste, dass durch diesen Weg meine innere Kraft wieder ans Licht darf. Ich war mir aber zugleich auch bewusst, dass ich auf diesem Weg durch die Dunkelheit musste um uralte alte Ängste aufzulösen und so mein inneres Licht zu erfahren. Inzwischen habe ich mich vom Titel Priesterin der weiblichen Kraft wieder gelöst, da es für mich nicht in die neue Zeit passt. Und so gehe ich jetzt den Weg des Herzens, des Lichtes und der Liebe als Heilende Begleiterin.",
// 	// },
// 	// {
// 	// 	key: 5,
// 	// 	content:
// 	// 		"Es ist mir eine Freude und eine Ehre nun Menschen auf ihrem Weg zu begleiten.",
// 	// },
// ];

const description = (
	<div>
		<br />
		Wie schön, dass Du hierher gefunden hast.
		<br />
		<br />
		Schon als Kind fühlte ich viel und nahm Dinge wahr, die ich nicht einordnen konnte. Stille und Frieden fand ich am ehesten am Wasser und in der Natur.
		Feinfühlig wie ich war, hätte ich am liebsten jedem meine Hände aufgelegt und mein Herz strahlen lassen. Der frühe Verlust meiner Mutter, die an den Folgen
		von Krebs starb, als ich 12 Jahre alt war, und eigene Erkrankungen führten mich zu den Fragen: “Warum werden wir krank und wie geschieht Heilung? Gibt es eine
		Seelenbestimmung? Was passiert mit der Seele nach dem Tod? Wie können wir mit unseren Ahnen in Kontakt kommen?”. So machte ich mich als junge Frau auf die
		Suche und fand auf meinem Weg viele LehrerInnen.
		<br />
		<br />
		Das Leben führte mich mit Anfang 20 ins Allgäu zu meinem Mann und wir sind heute glückliche Eltern von fünf Söhnen. Unsere Kinder sind zu Hause geboren und
		darüber bin ich sehr glücklich. Immer wenn Zeit und Raum war und mein Mann Kinder und Haus hütete, besuchte ich Seminare und Ausbildungen, um Antworten auf
		meine Fragen zu finden und mich weiterzuentwickeln. Mit ca. 40 Jahren bekam ich starke Menstruationsbeschwerden und hatte eine Zyste am Eierstock. Ich
		probierte alternative Heilmethoden, erforschte, welche Botschaften mir mein Körper übermitteln wollte und fand schließlich Heilung meiner Weiblichkeit auf
		zwei Reisen nach Guatemala. Viele Tränen flossen in den Schoß von Mama Erde, in Feuerzeremonien transformierte ich alten Schmerz. Als die Schichten sich
		lösten, erinnerte ich mich an meine Gaben aus vergangenen Leben. Zutiefst dankbar für die Heilung kehrte ich nach Hause zurück und wusste: Nun ist es Zeit
		meinen Herzensweg zu gehen, meine Bestimmung zu leben und andere Menschen auf dem Weg ihrer Heilung zu begleiten.
		<br />
		<br />
		Heute weiß ich, dass Heilung auf mehreren Ebenen geschieht. Unsere Seele möchte wieder als Einheit erfahren werden. Unser erwachendes Bewusst-Sein lässt uns
		erinnern was wir in der Essenz sind. Die geistige Ebene wirkt auf die emotionale Ebene, dies wiederum führt zu Heilung auf der körperlichen Ebene.
		Größtmögliche Heilung geschieht wenn wir die Ebenen miteinander verbinden.
		<br />
		<br />
		So ist es mir eine Freude, Menschen auf ihrem ganz persönlichen Weg in ihre schöpferische Kraft, zur Entfaltung ihrer einzigartigen Gaben zu begleiten.
		<br />
		<br />
		Herzlich, Daniela Shan’Adrana, Heilende Begleiterin.
		<br />
		<br />
	</div>
);

const descriptionShort = (
	<div>
		<br />
		Wie schön, dass Du hierher gefunden hast.
		<br />
		<br />
		Schon als Kind fühlte ich viel und nahm Dinge wahr, die ich nicht einordnen konnte. Stille und Frieden fand ich am ehesten am Wasser und in der Natur.
		Feinfühlig wie ich war, hätte ich am liebsten jedem meine Hände aufgelegt und mein Herz strahlen lassen. Der frühe Verlust meiner Mutter, die an den Folgen
		von Krebs starb, als ich 12 Jahre alt war, und eigene Erkrankungen führten mich zu den Fragen: “Warum werden wir krank und wie geschieht Heilung? Gibt es eine
		Seelenbestimmung? Was passiert mit der Seele nach dem Tod? Wie können wir mit unseren Ahnen in Kontakt kommen?”. So machte ich mich als junge Frau auf die
		Suche und fand auf meinem Weg viele LehrerInnen.
		<br />
		<br />
		... weiterlesen
		<br />
		<br />
	</div>
);

const MyWay = () => {
	const [showMore, setShowMore] = useState(false);
	const topRef = useRef(null);

	const showMoreHandler = () => {
		if (showMore) {
			scrollToTopHandler();
		}
		setShowMore(!showMore);
	};

	const scrollToTopHandler = () => {
		window.scrollTo({
			top: topRef.current.offsetTop,
			left: 0,
			behavior: "smooth",
		});
	};

	return (
		<section ref={topRef} className="gray-background">
			<Container>
				<Row>
					<div>
						<Col md={12}>
							<h2 className="display-6">MEIN WEG</h2>
							<hr />
						</Col>
						<Col md={12}>{showMore ? description : descriptionShort}</Col>
						<Col>
							<div style={{ textAlign: "left" }}>
								<ButtonGroup>
									{!showMore && (
										<Button onClick={() => showMoreHandler()} className="btn-secondary" style={{ width: "50px" }}>
											+
										</Button>
									)}
									{showMore && (
										<Button onClick={() => showMoreHandler()} className="btn-secondary" style={{ width: "50px" }}>
											-
										</Button>
									)}
								</ButtonGroup>
							</div>
						</Col>
					</div>
				</Row>
			</Container>
		</section>
	);
};

export default MyWay;
