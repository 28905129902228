import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const ConsentContext = createContext();

export const useConsent = () => useContext(ConsentContext);

export const ConsentProvider = ({ children }) => {
	const [cookieSettings, setCookieSettings] = useState({
		necessary: true,
		preferences: false,
		statistics: false,
		marketing: false,
	});

	useEffect(() => {
		// Versuchen, die Cookie-Einstellungen zu laden
		const storedSettings = Cookies.get("cookieConsentSettings");
		if (storedSettings) {
			const parsedSettings = JSON.parse(storedSettings)
			setCookieSettings(parsedSettings);
		}
	}, []);

	const updateCookieSettings = (settings) => {
		setCookieSettings(settings);
		// Speichern der Einstellungen in einem Cookie
		Cookies.set("cookieConsentSettings", JSON.stringify(settings), { expires: 365 }); // Setzt das Ablaufdatum auf 1 Jahr
	};

	return <ConsentContext.Provider value={{ cookieSettings, updateCookieSettings }}>{children}</ConsentContext.Provider>;
};
