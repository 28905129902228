import React from "react";

const TestiMonialsDetails = ({ testiMonialDetail }) => {
	const { name, course, description, img } = testiMonialDetail;

	return (
			<div className="item">
				<div className="shadow-effect">
					<img className="img-circle" src={img} alt="" />
					<div className="testimonial-name">
						<h4 style={{ marginBottom: 0 }}>{name}</h4>
						<small>{course}</small>
					</div>
					<p>{description}</p>
				</div>
			</div>
	);
};

export default TestiMonialsDetails;
