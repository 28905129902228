import React, { useState } from "react";
import { Card } from "react-bootstrap";

const Post = (props) => {
	const [showMore, setShowMore] = useState(false);
	function createContent(content) {
		return { __html: content };
	}

	const content = props.props.content.rendered.replace(/<p>&nbsp;<\/p>/g, "");

	let showLessContent = content.slice(0, 500);

	showLessContent = showLessContent.substr(
		0,
		Math.min(showLessContent.length, showLessContent.lastIndexOf(" "))
	) + " ...";

	return (
		<React.Fragment>
			{props && (
				<Card style={{ border: "1px solid" }}>
					<Card.Body>
						<Card.Title>
							{props.props &&
								props.props.title &&
								props.props.title.rendered && (
									<div
										dangerouslySetInnerHTML={createContent(
											props.props.title.rendered
										)}
										className="latoHeader"
									/>
								)}
						</Card.Title>
						{content && (
							<div>
								{!showMore ? (
									<span>
										<span
											dangerouslySetInnerHTML={createContent(showLessContent)}
										/>
										<p
											style={{
												textAlign: "right",
												color: "#d4b66b",
												margin: "0",
											}}
											className="show-more"
											onClick={() => setShowMore(!showMore)}
										>
											weiterlesen
										</p>
									</span>
								) : (
									<span>
										<span dangerouslySetInnerHTML={createContent(content)} />
										{/* <p style={{ textAlign: "right", color:"lightgray", margin:'0' }} className="show-more" onClick={() => setShowMore(!showMore)}>einklappen</p> */}
									</span>
								)}
							</div>
						)}
					</Card.Body>
				</Card>
			)}
		</React.Fragment>
	);
};

export default Post;
