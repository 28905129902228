import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";

const CV = () => {
	return (
		<section className="gray-background">
			<Container>
				<Row>
					<Col md={12}>
						<h2 className="display-6">SPIRITUELLER LEBENSLAUF & AUSBILDUNGEN:</h2>
					</Col>
					<Col>
						<Table responsive borderless className="table-primary">
							<tbody>
								<tr>
									<td style={{ minWidth: "70px" }}>
										<strong className="cvZahlen">1992 - 1998</strong>
									</td>
									<td className="cvBeschreibung">Belehrungen des Dalai Lama, Sikkim/Spanien/Deutschland</td>
								</tr>
								<tr>
									<td>
										<strong className="cvZahlen">2000 - 2010</strong>
									</td>
									<td className="cvBeschreibung">Meditation (ZaZen) und Gebärdenspiel, Silvia Ostertag, Allgäu</td>
								</tr>
								<tr>
									<td>
										<strong className="cvZahlen">2006 - 2020</strong>
									</td>
									<td className="cvBeschreibung">Tanz der 5 Rhythmen, Soul Medicine, Movement Medicine, Deutschland</td>
								</tr>
								<tr>
									<td>
										<strong className="cvZahlen">2017 - 2018</strong>
									</td>
									<td className="cvBeschreibung">Medialität, Katja Davidsen, Malaga, Silvia Hollenstein, Schweiz</td>
								</tr>
								<tr>
									<td>
										<strong className="cvZahlen">2018</strong>
									</td>
									<td className="cvBeschreibung">Schamanischer Kurs „One Spirit Medicine“, Alberto Villoldo, Allgäu</td>
								</tr>
								<tr>
									<td>
										<strong className="cvZahlen">2018</strong>
									</td>
									<td className="cvBeschreibung">Meditationslehrer-Ausbildung und Assistenz, Eckhart Wunderle, München</td>
								</tr>
								<tr>
									<td>
										<strong className="cvZahlen">2020 - 2021</strong>
									</td>
									<td className="cvBeschreibung">Persönliche Reise in meine schöpferische Kraft Allgäu/Guatemala</td>
								</tr>
								<tr>
									<td>
										<strong className="cvZahlen">2022</strong>
									</td>
									<td className="cvBeschreibung">Ausbildung zur Doula, Begleiterin für Schwangere, Bridget Michael, Allgäu</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default CV;
