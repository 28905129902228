import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {

	const year = new Date().getFullYear();

	return (
		<section style={{ backgroundColor: "white", paddingTop: "40px" }}>
			<footer>
				<Container>
					<ul className="social" style={{justifyContent:"left"}}>
						<li >
							<a
								href="https://www.instagram.com/sein_im_licht/"
								target="_blank"
								rel="noopener noreferrer"
								style={{color:"#d4b66b"}}
							>
								<i className="fab fa-instagram"></i>
							</a>
						</li>
						<li style={{color:"black", textAlign:"center",}}>
							<a
								href="https://t.me/joinchat/ZYj_823v2wQxM2Qy"
								target="_blank"
								rel="noopener noreferrer"
								style={{color:"#d4b66b"}}
							>
								<i className="fab fa-telegram-plane"></i>
							</a>
						</li>
					</ul>
					<Row>
						<Col>
						<div >
							<p style={{textAlign: 'left'}}>Copyright © {year} SEIN IM LICHT – Daniela Faber.</p>
						</div>
						</Col>
						<Col>
							<ul className="menu">
								<li style={{display:'none'}}>
									<Link to="/feed/podcast">Feed</Link>
								</li>
								<li>
									<Link to="/rechtliches">Rechtliches</Link>
								</li>
								<li>
									<Link to="/privacy">Datenschutzerklärung</Link>
								</li>
								<li>
									<Link to="/impressum">Impressum</Link>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</footer>
		</section>
	);
}

export default Footer;
