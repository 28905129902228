import React, { useState } from "react";
import useWindowDimensions from "./useWindowDimensions";
import { Navbar, Nav, Image, Container, Modal } from "react-bootstrap";
import logo from "../images/2021_logo_zeichen_seinimlicht.jpeg";
import LinkComp from "./LinkComp";

import "../hamburger.css";

const Header = () => {
	const { width } = useWindowDimensions();
	const fullscreen = true;
	const [show, setShow] = useState(false);
	const [open, setOpen] = useState(false);
	const [spin, setSpin] = useState(false);

	function handleShow() {
		if (show === true) {
			setOpen(false);
			setTimeout(function () {
				setShow(false);
			}, 100);
		} else {
			setOpen(true);
			setTimeout(function () {
				setShow(true);
			}, 100);
		}
	}

	const spinHandler = () => {
		if (!spin) {
			setSpin(true);
			setTimeout(function () {
				setSpin(false);
			}, 2000);
		}
	};

	const handleLinkClicked = () => {
		setShow(false);
		setTimeout(function () {
			setOpen(false);
		}, 50);
		// setTimeout(function () {
		// 	navigate(`#${link}`);
		// }, 300);
	};

	const closeHandler = () => {
		setShow(false);
		setOpen(false);
	};

	const CloseButton = () => {
		return (
			<div>
				<button
					type="button"
					className={
						!open
							? "hamburger animated fadeInLeft is-closed"
							: "hamburger animated fadeInLeft is-open"
					}
					onClick={() => handleShow()}
				>
					<span className="hamb-top"></span>
					<span className="hamb-middle"></span>
					<span className="hamb-bottom"></span>
				</button>
			</div>
		);
	};

	return (
		<div>
			<Container id="wrapper">
				<Navbar
					className="justify-content-end"
					style={
						width > 1200
							? { marginBottom: "-62px", paddingBottom: "0" }
							: { marginBottom: "-54px", paddingBottom: "0" }
					}
				>
					<Nav>
						<Nav.Link
							target="__blank"
							href="https://www.instagram.com/sein_im_licht/"
						>
							<i className="fab fa-instagram nav-social-button"></i>
						</Nav.Link>
						<Nav.Link href="mailto:info@seinimlicht.de?subject=Kontakt sein im Licht">
							<i className="far fa-envelope nav-social-button"></i>
						</Nav.Link>
						<Nav.Link href="tel:4917647161073">
							<i className="fas fa-phone-alt nav-social-button"></i>
						</Nav.Link>
						<Nav.Link
							target="__blank"
							href="https://t.me/joinchat/ZYj_823v2wQxM2Qy"
						>
							<i className="fab fa-telegram-plane nav-social-button"></i>
						</Nav.Link>
					</Nav>
				</Navbar>
				<Navbar
					expand="lg"
					style={
						width > 1200
							? { marginTop: "-43px", paddingTop: "0", alignItems: "flex-end" }
							: { marginTop: "-43px", paddingTop: "0" }
					}
				>
					<Navbar.Brand>
						<Image
							onMouseEnter={() => spinHandler()}
							alt="Logo Sein im Licht - Daniela Faber"
							src={logo}
							className={spin ? "headerLogo headerLogoRotation" : "headerLogo"}
							style={
								width > 1200 ? { marginLeft: "-6px" } : { marginLeft: "-10px" }
							}
						/>
						<h1
							style={
								width > 1200
									? {
											position: "relative",
											fontFamily: "Ingeborg",
											fontWeight: "500",
											fontSize: "26px",
											marginTop: "-16px",
											marginBottom: "0",
											color: "#d4b66b",
											letterSpacing: "0.0325em",
									  }
									: {
											position: "relative",
											fontFamily: "Ingeborg",
											fontWeight: "300",
											fontSize: "18px",
											marginBottom: "0",
											marginTop: "-16px",
											color: "#d4b66b",
											letterSpacing: "0.0325em",
									  }
							}
						>
							SEIN IM LICHT
						</h1>
					</Navbar.Brand>
					{/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
					{width > 1200 ? (
						<Nav>
							<LinkComp target="news">
								<div className="nav-link">AKTUELLES</div>
							</LinkComp>
							<LinkComp target="ausbildung">
								<div className="nav-link">Ausbildung</div>
							</LinkComp>
							<LinkComp target="seminare">
								<div className="nav-link">SEMINARE / KURSE</div>
							</LinkComp>
							<LinkComp target="mein-weg">
								<div className="nav-link">MEIN WEG</div>
							</LinkComp>
							<LinkComp target="dein-weg">
								<div className="nav-link">DEIN WEG</div>
							</LinkComp>
							<LinkComp target="podcast">
								<div className="nav-link">PODCAST</div>
							</LinkComp>
						</Nav>
					) : (
						<CloseButton />
					)}
				</Navbar>
			</Container>
			<Modal show={show} fullscreen={fullscreen} onHide={() => closeHandler()}>
				<Container style={{ height: "100vh" }}>
					<Modal.Header>
						<Modal.Title className="ingeborgHeader">Sein im Licht</Modal.Title>
						<div style={{ marginBottom: "32px" }}>
							<CloseButton onClick={() => closeHandler()} />
						</div>
					</Modal.Header>
					<Modal.Body>
						<div
							style={{
								textAlign: "center",
								fontSize: "22px",
								fontFamily: "Lato",
							}}
						>
							<LinkComp target="news">
								<p
									style={{
										textAlign: "center",
										cursor: "pointer",
									}}
									onClick={() => handleLinkClicked()}
								>
									AKTUELLES
								</p>
							</LinkComp>
							<LinkComp target="ausbildung">
								<p
									style={{
										textAlign: "center",
										cursor: "pointer",
									}}
									onClick={() => handleLinkClicked()}
								>
									AUSBILDUNG
								</p>
							</LinkComp>
							<LinkComp target="seminare">
								<p
									style={{
										textAlign: "center",
										cursor: "pointer",
									}}
									onClick={() => handleLinkClicked()}
								>
									SEMINARE / KURSE
								</p>
							</LinkComp>
							<LinkComp target="mein-weg">
								<p
									style={{
										textAlign: "center",
										cursor: "pointer",
									}}
									onClick={() => handleLinkClicked()}
								>
									MEIN WEG
								</p>
							</LinkComp>
							<LinkComp target="dein-weg">
								<p
									style={{
										textAlign: "center",
										cursor: "pointer",
									}}
									onClick={() => handleLinkClicked()}
								>
									DEIN WEG
								</p>
							</LinkComp>
							<LinkComp target="podcast">
								<p
									style={{
										textAlign: "center",
										cursor: "pointer",
									}}
									onClick={() => handleLinkClicked()}
								>
									PODCAST
								</p>
							</LinkComp>
						</div>
					</Modal.Body>
				</Container>
			</Modal>
		</div>
	);
};

export default Header;
