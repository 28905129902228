import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import MailchimpForm from "./MailchimpForm";

const Mailchimp = () => {
	const url = "https://seinimlicht.us14.list-manage.com/subscribe/post?u=cd8352ba2bcaff006aa5a70c0&id=532b567a4d";

	return (
		<div className="mail-chimp-background">
			<section>
				<Container>
					<Row className="mail-chimp-background-inside">
						<Col>
							<div
								style={{
									marginTop: "30px",
									fontWeight: "bold",
									fontSize: "30px",
									textTransform: "uppercase"
								}}
							>
								Melde Dich an zum Newsletter „LICHTBLICK“ 
							</div>
							<p>
								Melde Dich mit deiner e-Mail-Adresse an und erhalte immer alle
								Neuigkeiten, Updates und aktuellen Events über meinen Newsletter
							</p>

							<MailchimpSubscribe
								url={url}
								render={(props) => {
									const { subscribe, status, message } = props || {};
									return (
										<MailchimpForm
											status={status}
											message={message}
											onValidated={(formData) => subscribe(formData)}
										/>
									);
								}}
							/>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
};

export default Mailchimp;
