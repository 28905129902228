import React, { useRef } from "react";
import "../highlightCourses.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import ContactModal from "./ContactModal";

import useWindowDimensions from "./useWindowDimensions";
// import pdf from "../data/2023_onepager_seinimlicht_wasser_x3.pdf";
// import pdf from "../data/2023_seinimlicht_ausbildung_hüterIn_wasser_2024.pdf";

import Lichterkreis from "../images/Lichterkreis.jpeg";
import Hueterin from "../images/IMG_5264x.jpeg";
import SchöperfischeKraft from "../images/SchöperfischeKraft.jpeg";
import { useConsent } from "../context/ConsentContext";

const HighlightCourses = () => {
	const { width } = useWindowDimensions();
	const carouselRef = useRef(0);
	const { cookieSettings, updateCookieSettings } = useConsent();

	//Owl Carousel Settings
	const options = {
		loop: true,
		center: true,
		autoplay: false,
		startPosition: 0,
		// autoplayHoverPause:true,
		dots: true,
		nav: width >= 1200 ? true : false,
		navText: [
			'<span class="arrowCoursesCarousel prevCoursesCarousel fas fa-chevron-left"></span>',
			'<span class="arrowCoursesCarousel nextCoursesCarousel fas fa-chevron-right"></span>',
		],
		lazyLoad: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 1,
			},
			1200: {
				items: 2,
			},
		},
	};
	return (
		<section id="testimonial">
			<Container>
				<div className="row">
					<Col md={12} style={{ marginBottom: "24px" }}>
						<h2 className="display-6">Ausbildung & Begleitung</h2>
						<hr />
					</Col>
					<Col md={12} style={{ marginTop: "12px" }}>
						<OwlCarousel
							id="highlight-courses"
							ref={carouselRef}
							{...options}
							// onChanged={(e) => console.log(e.relatedTarget)}
						>
							<div className="item" id="HüterIn">
								<div className="shadow-effect-courses-carousel">
									<img className="img-course" style={{ objectPosition: "center 90%" }} src={Hueterin} alt="" />
									<div className="item-content">
										<div className="course-name">
											<h4 style={{ marginBottom: 0, marginTop: "14px" }}>Werde HüterIn des Wassers</h4>
											{/* <small>{course}</small> */}
										</div>
										<p className="item-content-headline">Ausbildungsstart: 14. März 2024</p>
										<p>
											Das Wasser ist der Ursprung allen Lebens und eines der wichtigsten Themen unserer Zeit. Unser Körper besteht zu 70-80% aus Wasser, so wie die Erde.
											Wir brauchen Heilung ebenso wie die Gewässer der Erde.
										</p>
										<p>
											Als HüterIn des Wassers lernst Du, dich wieder mit dem Wasser und den Wasserwesen zu verbinden, Wasser aufzuladen, Rituale und Zeremonien für
											Gruppen, Botschaften empfangen, die heilsame Kraft des Wassers und vieles mehr.
										</p>
										<p className="item-content-headline">Ausbildungs-Inhalt & -Rahmen</p>
										<ul>
											<li>13 online Termine über Zoom</li>
											<li>zusätzliche Live-Zeremonien</li>
											<li>Ausbildungsstart Frühjahr 2024</li>
											<li>Ausbildungsende ca. November 2024</li>
											<li>Begrenzte TeilnehmerInnenzahl</li>
										</ul>
										<Button
											className="caption-buttons"
											href={"https://d26i8k1jj1dow1.cloudfront.net/customer_websites/sein-im-licht/2024_onepager_seinimlicht_wasser.pdf"}
											download
											target="__blank"
											style={{ marginTop: "2px", marginBottom: "4px" }}>
											Download Info PDF
										</Button>
										<ContactModal subject={"HüterInnen des Wassers"} classes="btn-secondary, caption-buttons" btntitle="Jetzt anmelden*" />
										<p>*Erstgespräch (1/2 Std. gratis)</p>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="shadow-effect-courses-carousel">
									<img className="img-course" src={Lichterkreis} alt="" />
									<div className="item-content">
										<div className="course-name">
											<h4 style={{ marginBottom: 0, marginTop: "14px" }}>Entfache Dein inneres Licht</h4>
											{/* <small>{course}</small> */}
										</div>
										<p className="item-content-headline">Kurs startet am Dienstag, 06.02.24</p>
										<p>
											In einem kleinen geschützten Kreis bist Du eingeladen, Dein inneres Licht zu entzünden und Dein wahres SEIN zu erfahren. In diesem Kurs entfalten wir
											die Ur-Essenzen unseres Herzens. Unsere Herzenskraft erwacht, wenn wir emotionale Lasten und innere Blockaden lösen und die Ur-Essenzen ins Fließen
											bringen.
										</p>
										<p>Hier ist Raum für Deine persönliche, spirituelle Entfaltung. Zusätzliche Einzelbegleitung möglich.</p>
										<p className="item-content-headline">Kurs-Inhalt & -Rahmen</p>
										<ul>
											<li>Mit Meditation, Erforschen und Botschaften aus der geistigen Welt</li>
											<li>9 Termine online über Zoom</li>
											<li>Der neue Kurs startet am Dienstag 06. Februar 2024</li>
											<li>Begrenzte TeilnehmerInnenzahl</li>
										</ul>
										<Button
											className="caption-buttons"
											href={"https://d26i8k1jj1dow1.cloudfront.net/customer_websites/sein-im-licht/2024_onepager_seinimlicht_inneresLicht.pdf"}
											download
											target="__blank"
											style={{ marginTop: "2px", marginBottom: "4px" }}>
											Download Info PDF
										</Button>
										<ContactModal subject={"Lichter-Kreis-Jahreskurs"} classes="btn-secondary, caption-buttons" btntitle="Jetzt anmelden*" />
										<p>*Erstgespräch (1/2 Std. gratis)</p>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="shadow-effect-courses-carousel">
									<img className="img-course" src={SchöperfischeKraft} alt="" />
									<div className="item-content">
										<div className="course-name">
											<h4 style={{ marginBottom: 0, marginTop: "14px" }}>Entfalte Deine schöpferische Kraft</h4>
										</div>
										<p className="item-content-headline">Individuelle Begleitung</p>
										<p>
											Wünscht Du dir, noch mehr in Deine schöpferische Kraft zu kommen? Möchtest Du Deine inneren Gaben entdecken und Dein Potential entfalten ? Möchtest
											Du Deine Bestimmung leben?
										</p>
										<p>
											Der Weg in die neue Zeit ruft uns auf, das Alte hinter uns zu lassen, das Neue ins Leben zu bringen und aus der Verbindung mit dem Herzen zu leben.{" "}
											<br />
											Transformation und Heilung geschieht auf mehreren Ebenen. Damit Du in Deine schöpferische Kraft kommst und Deine Bestimmung leben kannst, schauen wir
											gemeinsam, was es braucht. Du entscheidest, wie weit ich dich auf deinem Weg begleite, denn jeder Weg ist individuell und entfaltet sich auf seine
											Weise.
										</p>
										<p className="item-content-headline">Inhalt & -Rahmen</p>
										<ul>
											<li>Positive Affirmationen</li>
											<li>Stärken deiner inneren Kraftquellen</li>
											<li>Potenzial deiner Ahnen erwecken</li>
											<li>Aktivieren der Verbindung mit Deinem Höheren Selbst</li>
											<li>Schoßraum- und Herz-Heilung</li>
											<li>Emotionscode</li>
											<li>Heilsymbol & Seelenbild</li>
											<li>Meditation & Ritual</li>
											<li>Feuerzeremonie</li>
										</ul>
										<ContactModal subject={"Der Weg in Deine schöpferische Kraft"} classes="btn-secondary, caption-buttons" btntitle="Jetzt anmelden*" />
										<p>*Erstgespräch (1/2 Std. gratis)</p>
									</div>
								</div>
							</div>
						</OwlCarousel>
					</Col>
					<Col md={12} style={{ marginBottom: "24px" }}>
						<h3 className="display-7">Im Gespräch mit den Hüter:innen des Wassers</h3>
						<Row>
							{cookieSettings.preferences ? (
								<>
									<Col sm={12} lg={4} style={{ paddingBottom: "16px" }}>
										<div className="video-responsive">
											<iframe
												src="https://www.youtube.com/embed/_Z1Vqy1GJDY?si=OzjpfqFcWDo6ggZf"
												title="YouTube video player 1"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												allowFullScreen></iframe>
										</div>
									</Col>
									<Col sm={12} lg={4} style={{ paddingBottom: "16px" }}>
										<div className="video-responsive">
											<iframe
												src="https://www.youtube.com/embed/wjWc8DrVRE8?si=HeOJ4TRB8JvzOzRT"
												title="YouTube video player 2"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												allowFullScreen></iframe>
										</div>
									</Col>
									<Col sm={12} lg={4} style={{ paddingBottom: "16px" }}>
										<div className="video-responsive">
											<iframe
												src="https://www.youtube.com/embed/zq_mxBj3hnw?si=TWbl176GPoM8erBG"
												title="YouTube video player"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
												allowFullScreen></iframe>
										</div>
									</Col>
								</>
							) : (
								<>
									<Col sm={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
										<h3 style={{ fontWeight: 400, textAlign: "center" }}>Bitte aktzetiere die Drittanbieter Cookies, um dir diesen Inhalt ansehen zu können</h3>
										<Button
											onClick={() =>
												updateCookieSettings({
													necessary: true,
													preferences: true,
													statistics: false, // Manipulated
													marketing: false, // Manipulated
												})
											}>
											Akzeptieren
										</Button>
									</Col>
									<Col sm={12} lg={4} style={{ paddingBottom: "16px" }}>
										<div className="video-responsive gray-background"></div>
									</Col>
									<Col sm={12} lg={4} style={{ paddingBottom: "16px" }}>
										<div className="video-responsive gray-background" />
									</Col>
									<Col sm={12} lg={4} style={{ paddingBottom: "16px" }}>
										<div className="video-responsive gray-background" />
									</Col>
								</>
							)}
						</Row>
					</Col>
				</div>
			</Container>
		</section>
	);
};

export default HighlightCourses;
