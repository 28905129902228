import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BlogPosts from "./components/BlogPosts";
import Header from "./components/Header";
import Carousel from "./components/Carousel";
import HighlightCourses from "./components/HighlightCourses";
import Section1 from "./components/Section1";
import News from "./components/News";
import Courses from "./components/Courses";
import MyWay from "./components/MyWay";
import Vita from "./components/Vita";
import CV from "./components/CV";
import Mailchimp from "./components/Mailchimp";
import Podcast from "./components/Podcast";
import SocialMedia from "./components/SocialMedia";
import Footer from "./components/Footer";
import ContactModal from "./components/ContactModal";
// import CookieBanner from "./components/CookieBanner";
import ReadingBar from "./components/ReadingBar";
import PathToPower from "./components/PathToPower";
import Testimonial from "./components/Testimonial";
import useWindowDimensions from "./components/useWindowDimensions";
import TelegramBanner from "./components/TelegramBanner";

function App() {
	const location = useLocation();

	const [scrollView, setScrollView] = useState();
	const { width } = useWindowDimensions();

	let anchor = location.hash;

	useEffect(() => {
		if (anchor) {
			setScrollView(true);
		}
	}, [anchor]);

	return (
		<React.Fragment>
			<ReadingBar />
			<div>
				<Header />
			</div>
			<Section1 />
			<Carousel />
			<div id="news">
				<News />
			</div>
			<div>
				<TelegramBanner />
			</div>
			<div id="ausbildung">
				<HighlightCourses />
			</div>
			<div id="seminare" style={scrollView && width <= 414 ? { minHeight: "295vh" } : { minHeight: "" }}>
				<Courses />
			</div>

			<div>
				<Testimonial />
			</div>
			<div id="mein-weg">
				<MyWay />
			</div>
			<Vita />
			<CV />
			<div id="newsletter">
				<Mailchimp />
			</div>
			<div id="dein-weg">
				<PathToPower />
			</div>
			<div>
				<SocialMedia />
			</div>
			<div id="podcast" style={{ minHeight: "100vh" }}>
				<Podcast />
			</div>
			<div id="blog" style={{ minHeight: "100vh" }}>
				<BlogPosts />
			</div>
			<ContactModal btntitle="Kontakt" subject="Kontakt" classes="btn-secondary contact-button" />
			<Footer />
		</React.Fragment>
	);
}

export default App;
