import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookie } from '@fortawesome/free-solid-svg-icons';

const CookieButton = ({ setOpen }) => {
  return (
    <Button onClick={() => setOpen()} className={"btn-secondary cookie-button"} style={{borderRadius: "5px"}}>
      <FontAwesomeIcon icon={faCookie} style={{ width: '28px', height: '30px' }} />
    </Button>
  );
};

export default CookieButton;
