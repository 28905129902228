import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import image from "../images/bemalt_DIA1.jpeg";

const Vita = () => {
	return (
		<Container>
			<Row>
				<Col md={9}>
					<section>
						<h2 className="display-6">VITA</h2>
						<hr />
						<p>
							<span style={{ color: "black" }}>1974 | </span>geboren und
							aufgewachsen in München - seit 27 Jahren mit Familie im Allgäu
							lebend - Mutter von 5 wundervollen Söhnen - Familien-Café
							gegründet - Montessori-Schule mit aufgebaut - Begleitung von
							Flüchtlingen in unserer Gemeinde - Mitarbeit im
							Mehrgenerationenhaus - begeisterte Tänzerin - tiefe Liebe und
							Mitgefühl zu Mensch, Tier und Natur.
						</p>
						<div className="text-center" style={{ color: "black" }}>
							<p>
								"Ein jedes Sternenkind will in seinem Licht erinnert werden,
								auch wenn es sich vorübergehend in seinem Schatten verliert. Du
								bist das Licht hinter dem Schatten. Entfalte dein Herz und lass
								dein Licht erstrahlen."
							</p>
						</div>
					</section>
				</Col>
				<Col md={3}>
					<div
						style={{
							backgroundImage: `url(${image})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundPosition: "80% 0%",
							height: "100%",
							boxShadow: "130px 20px 50px #FEFEFE",
						}}
					></div>
				</Col>
			</Row>
		</Container>
	);
};

export default Vita;
