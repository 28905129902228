import React from "react";
import { Image } from "react-bootstrap";
import logozeichen from "../images/2022_LogoZeichen_SeinImLicht.png";

const Loading = () => {
  return (
    <div>
      <Image src={logozeichen} className="rotateZeichen"/>
      <p className="loading-Center">Beiträge werden geladen...</p>
    </div>
  );
};

export default Loading;
