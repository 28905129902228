import React, { useState, useEffect } from "react";
import {
	Row,
	Button,
	ButtonGroup,
	Container,
	Col,
	Image,
} from "react-bootstrap";
import Post from "./Post";
import LinkComp from "./LinkComp";
import Loading from "./Loading";

const url = "https://backend.seinimlicht.de/wp-json/wp/v2/";

const BlogPosts = () => {
	const [counter, setCounter] = useState(3);
	const [category, setCategory] = useState(9);
	const [subCategory, setSubCategory] = useState(null);
	const [subCategoryName, setSubCategoryName] = useState(null);

	const [articles, setArticles] = useState();
	const [categories, setCategories] = useState();
	const [subCategories, setSubCategories] = useState();

	useEffect(() => {
		async function loadPosts() {
			// setBlogLoading(true)
			const response = await fetch(`${url}posts?per_page=100`);
			if (!response.ok) {
				// oups! something went wrong
				return;
			}
			const posts = await response.json();
			const filteredPosts = posts.filter(
				(article) => article.categories[0] === 8 || article.categories[0] === 9
			);
			setArticles(filteredPosts);
			// setBlogLoading(false)
		}

		async function loadCategories() {
			const response = await fetch(`${url}categories?per_page=100`);
			if (!response.ok) {
				// oups! something went wrong
				return;
			}
			const categoriesList = await response.json();
			const filtered = categoriesList
				.filter((category) => category.id === 8 || category.id === 9)
				.filter((category) => category.slug !== "uncategorized");
			setCategories(filtered);
			setSubCategories(
				categoriesList.filter((category) => category.parent !== 0)
			);
		}

		loadPosts();
		loadCategories();
	}, []);

	let articlesList = [];
	let subCategoriesList =
		subCategories &&
		subCategories.sort(function (a, b) {
			return b.id - a.id;
		});

	if (subCategory != null) {
		articlesList =
			articles &&
			articles.filter(
				(article) =>
					article.categories[1] === subCategory &&
					article.categories[0] === category
			);
	}
	if (subCategory == null) {
		if (category != null) {
			articlesList =
				articles &&
				articles.filter((article) => article.categories[0] === category);
		}
		if (category == null) {
			articlesList = articles && articles;
		}
	}

	const lengthArticles = articlesList && articlesList.length;

	const countHandler = () => {
		if (counter + 1 > lengthArticles) {
			setCounter(lengthArticles);
		} else {
			const newCounter = counter + 1;
			setCounter(newCounter);
		}
	};

	const lessHandler = () => {
		setCounter(3);
	};

	const selectCategoryHandler = (a) => {
		setSubCategory(null);
		if (category === a) setCategory(null);
		else {
			setCategory(a);
			const listOfItems = articles.filter(
				(article) => article.categories[0] === a
			).length;
			if (listOfItems > counter) {
				setCounter(counter);
			}
			if (listOfItems < counter) {
				if (listOfItems > 3) {
					setCounter(listOfItems);
				} else {
					setCounter(3);
				}
			}
		}
	};

	const setSubCategoryHandler = (a, name) => {
		if (subCategory === a) {
			setSubCategory(null);
			setSubCategoryName(null);
		} else {
			setSubCategory(a);
			setSubCategoryName(name);
		}
	};

	return (
		<div className="gray-background">
			{articles && categories && subCategories ? (
				<section>
					<Container>
						<Row>
							<Col>
								<h2 className="display-6">Blog und Botschaften</h2>
								<Row>
									<div
										className="podcast-series-container"
										style={{ justifyContent: "space-between" }}
									>
										<div className="podcast-series-container">
											<ButtonGroup>
												{categories &&
													categories.map((a) => (
														<Button
															style={{ width: "100%" }}
															key={a.id}
															className={
																a.id === category
																	? "btn-secondary active category-button"
																	: "btn-secondary category-button"
															}
															onClick={() => selectCategoryHandler(a.id)}
														>
															{a.slug}{" "}
														</Button>
													))}
											</ButtonGroup>
										</div>
									</div>
								</Row>
								<Row>
									{subCategories && category === 9 && (
										<div>
											<h5 className="latoHeader" style={{ marginTop: "0" }}>
												Blog Serien
											</h5>
											<div className="blog-series-container">
												{subCategoriesList
													.filter(
														(subcategory) => subcategory.parent === category
													)
													.map((subcategory) => (
														<div
															key={subcategory.id}
															style={{ flexDirection: "column" }}
															className="blog-wrapper"
															onClick={() =>
																setSubCategoryHandler(
																	subcategory.id,
																	subcategory.name
																)
															}
														>
															<Image
																style={
																	subcategory.id === subCategory
																		? { filter: "none", border: "1px solid" }
																		: null
																}
																className="blogSeriesImg "
																src={subcategory.description.split('"')[3]}
																alt={`Blog Serie - ${subcategory.name}`}
															/>
															<div className="blogSeriesText ">
																{subcategory.name}
															</div>
															<div className="blogSeriesTextBackground"></div>
														</div>
													))}
											</div>
											{subCategoryName ? (
												<h5 className="latoHeader">
													Folgen der Serie "{subCategoryName}"
												</h5>
											) : (
												<h5 className="latoHeader">Alle Blog Folgen </h5>
											)}
										</div>
									)}
								</Row>

								<Row>
									<Col>
										{articlesList &&
											articlesList.slice(0, counter).map((article) => (
												<Col key={article.id} style={{ marginBottom: "20px" }}>
													<Post props={article} />
												</Col>
											))}
									</Col>
								</Row>
								<div style={{ textAlign: "right" }}>
									<ButtonGroup>
										{counter > 3 && (
											<LinkComp target="blog">
												<Button
													onClick={lessHandler}
													disabled={counter <= 2}
													className="btn-secondary minus-button"
												>
													-
												</Button>
											</LinkComp>
										)}
										{lengthArticles > counter && (
											<Button
												onClick={countHandler}
												disabled={articlesList.length === counter}
												className={
													counter <= 3
														? "btn-secondary plus-button-only"
														: "btn-secondary plus-button"
												}
											>
												+
											</Button>
										)}
									</ButtonGroup>
									{/* <div>
								{articlesList.length - counter > 0 && (
									<div>
										noch {articlesList.length - counter} weitere
									</div>
								)}
							</div> */}
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default BlogPosts;
