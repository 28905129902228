import React, { useState } from "react";
import { Row, Col, Card, Container, Image } from "react-bootstrap";
import LinkComp from "./LinkComp";
import begleitung from "../images/2021_Button_Begleitung_ohne.png";
import meinWeg from "../images/2021_Button_MeinWeg_ohne.png";
import courses from "../images/2021_Button_SeminareKurse_ohne.png";

const News = () => {
	const [buttonHoverCourses, setButtonHoverCourses] = useState(false);
	const [buttonHoverBegleitung, setButtonHoverBegleitung] = useState(false);
	const [buttonHoverMeinWeg, setButtonHoverMeinWeg] = useState(false);

	return (
		<React.Fragment>
			<section
				// className="gray-background"
				style={{ paddingTop: "40px" }}
			>
				<Container>
					<Row style={{ paddingTop: "20px", paddingBottom: "20px"}}>
						{/* <Col md={12}>
							<h2 className="display-6">AKTUELLES</h2>
						</Col> */}
						<Col
							xs={4}
							sm={4}
							md={4}
							lg={4}
							xl={4}
							style={{ textAlign: "right" }}
						>
							<LinkComp target="podcast">
								{!buttonHoverCourses ? (
									<Card
										className="card2"
										style={{ float: "right"}}
										onMouseEnter={() => setButtonHoverCourses(true)}
									>
										<div className="card_title">Podcast</div>
									</Card>
								) : (
									<Image
										style={{ cursor: "pointer" }}
										src={courses}
										className="cardImg"
										onMouseLeave={() => setButtonHoverCourses(false)}
									/>
								)}
							</LinkComp>
						</Col>
						<Col
							xs={4}
							sm={4}
							md={4}
							lg={4}
							xl={4}
							style={{ textAlign: "center" }}
						>
							<a
								target="__blank"
								href="https://youtube.com/playlist?list=PLvMzt0NGH912n8JvCpSP4DwRTNxIR6YSg"
							>
								{!buttonHoverBegleitung ? (
									<Card
										className="card2"
										onMouseEnter={() => setButtonHoverBegleitung(true)}
									>
										<div className="card_title">YouTube</div>
									</Card>
								) : (
									<Image
										src={begleitung}
										className="cardImg"
										onMouseLeave={() => setButtonHoverBegleitung(false)}
									/>
								)}
							</a>
						</Col>
						<Col
							xs={4}
							sm={4}
							md={4}
							lg={4}
							xl={4}
							style={{ textAlign: "left" }}
						>
							<LinkComp target="blog">
								{!buttonHoverMeinWeg ? (
									<Card
										className="card2"
										style={{ float: "left"}}
										onMouseEnter={() => setButtonHoverMeinWeg(true)}
									>
										<div className="card_title">Blog</div>
									</Card>
								) : (
									<Image
										style={{ cursor: "pointer" }}
										src={meinWeg}
										className="cardImg"
										onMouseLeave={() => setButtonHoverMeinWeg(false)}
									/>
								)}
							</LinkComp>
						</Col>
					</Row>
				</Container>
			</section>
		</React.Fragment>
	);
};

export default News;
