import React from "react";
import "../testimonial.css";

import TestiMonialsDetails from "./TestiMonialsDetails";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Col, Container } from "react-bootstrap";

import logo from "../images/2021_logo_zeichen_seinimlicht.jpeg";
import Julia from "../images/Julia.jpeg";
import Diana from "../images/Diana.png";
import useWindowDimensions from "./useWindowDimensions";

const testiMonials = [
	{
		name: "Sally",
		description:
			"Als Mutter von 5 Kindern war ich an einem Punkt in meinem Leben, an dem ich sehr müde und entkräftet war. Daniela hat mich in der letzten Schwangerschaft begleitet und mich in eine neue ungeahnte Richtung geführt. Ihre Meditationen halfen mir, ruhiger und klarer zu werden, meine Stärken zu finden und den Umgang mit mir selbst und meinen Kindern zu verändern. Ich habe wieder ein Gespür für mich selbst. Selbst bei der Geburt konnte ich einige Tipps anwenden. Ich bin ihr sehr dankbar. Danke Daniela!",
		course: "Coaching während Schwangerschaft",
		img: `${logo}`,
		key: 1,
	},
	{
		name: "Julia",
		description:
			"Mein Kinderwunsch hat mich zu Daniela geführt, nachdem ich den Rat der Schulmedizin, eine künstliche Befruchtung zu machen, nicht hören wollte. Daniela hat mir wieder das Vertrauen in mich und das Leben gegeben und, dass wir uns selbst heilen können. Die persönlichen Meditationen, die Gebärmutter-Reinigung und die Schoßraum-Reise halfen mir schwanger und eine glückliche Mutter zu werden. Danke, Daniela!",
		course: "Kinderwunsch",
		img: `${Julia}`,
		key: 2,
	},
	{
		name: "Sigrid",
		description:
			"Die Verbundenheit durch Danielas feine und klare Art zu spüren und zu erleben tat sehr gut. Der Kontakt zu meiner Urahnin fühlt sich an wie etwas, dass ich so lange vermisst hatte. Heute spüre ich, ich bin nicht allein. Die Arbeit mit Daniela war für mich überaus wertvoll. Durch das tolle Nachgespräch konnten wir zusammen reflektieren, wie groß der Unterschied von vorher zu nachher war. Die Zeremonie hat bei mir volle Wirkung gezeigt.",
		course: "Feuerzeremonie für Ahnenheilung",
		img: `${logo}`,
		key: 3,
	},
	{
		name: "Ursula",
		description:
			"Ich war so frei, erfüllt und glücklich als ich nach Hause gefahren bin. Ich fühlte mich während des Workshops gehalten in dem spirituellen Raum in den Daniela uns eingeladen hat. Alles was wir gemacht haben, Meditation, Tanz und Feuer war wie eine gemeinsame spirituelle Praxis, in dem wir sein konnten. Dafür möchte ich danken.",
		course: "Workshop “Sein im Einklang",
		img: `${logo}`,
		key: 4,
	},
	{
		name: "Rita",
		description:
			"Meine erste Feuer-Zeremonie mit Daniela war ganz meinen Ahnenlinien und ihrer Heilung geschenkt. Es war ein sehr bereicherndes Erlebnis und wirkt immer noch nach. Es war kraftvoll und schön. Spannend für mich war auch, dass gefühlt alles Prägende auf den Tisch gekommen ist. Mit sanften Nachfragen von Daniela wurde nach und nach mein Leben aufgefächert. Wie sehr fühle ich mich beschenkt, Dich, Liebe Daniela, in meinem Leben haben zu dürfen.",
		course: "Feuerzeremonie für Ahnenheilung",
		img: `${logo}`,
		key: 5,
	},
	{
		name: "Diana",
		description:
			"Daniela kann sehr gut erfühlen was bei ihrem Gegenüber gebraucht wird. Bei der Schoßraum-Reise hat sie mich liebevoll begleitet und genau dort abholen können, wo ich blockiert war.  Mit ihrem großen Schatz an Wissen und Erfahrung hat sie mir wunderbare Empfehlungen an die Hand gegeben, um die Heilung meines Schoßraums weiter zu unterstützen. Ich danke ihr von Herzen für ihre Begleitung ",
		course: "Schoßraumheilung",
		img: `${Diana}`,
		key: 6,
	},
];

const Testimonial = () => {
	const { width } = useWindowDimensions();

	//Owl Carousel Settings
	const options = {
		loop: true,
		center: true,
		autoplay: false,
		dots: true,
		nav: width >= 1200 ? true : false,
		navText: [
			'<span class="arrow prev fas fa-chevron-left"></span>',
			'<span class="arrow next fas fa-chevron-right"></span>',
		],
		lazyLoad: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 1,
			},
			1200: {
				items: 3,
			},
		},
	};
	return (
		<section id="testimonial">
			<Container>
				<div className="row">
					<Col md={12} className="mb-4">
						<h2 className="display-6">Feedback</h2>
						<hr />
					</Col>
					<Col md={12}>
						<OwlCarousel
							id="customer-testimonoals"
							className="owl-carousel"
							{...options}
						>
							{testiMonials.map((testiMonialDetail) => {
								return (
									<div key={testiMonialDetail.key}>
										<TestiMonialsDetails
											testiMonialDetail={testiMonialDetail}
										/>
									</div>
								);
							})}
						</OwlCarousel>
					</Col>
				</div>
			</Container>
		</section>
	);
};

export default Testimonial;
