import React, { useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import yuupless from "../images/2022_yuupless_da_logogray.png";

// AGB
const Imprint = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Container>
				<Link to="/">
					<p>zurück</p>
				</Link>
				<h1 className="display-6">Impressum</h1>
				<hr />
				<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
				<p>
					Daniela Faber
					<br />
					Meditation &amp; spirituelle Begleitung
					<br />
					Bischofswang 4<br />
					87672 Ro&szlig;haupten
				</p>

				<h2>Kontakt</h2>
				<p>
					Telefon: +49 176 47 161 073
					<br />
					E-Mail: info@seinimlicht.de
				</p>

				<h2>Umsatzsteuer-ID</h2>
				<p>
					Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
					Umsatzsteuergesetz:
					<br />
					53 760 428 144
				</p>

				<h2>Redaktionell verantwortlich</h2>
				<p>
					Daniela Faber
					<br />
					Bischofswang 4<br />
					D - 87672 Ro&szlig;haupten
					<br />
					info@seinimlicht.de
					<br />
					+49 176 47 161 073
				</p>

				<h2>
					Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
				</h2>
				<p>
					Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
					vor einer Verbraucherschlichtungsstelle teilzunehmen.
				</p>

				<p>
					Quelle Impressum und Datenschutzrichtlinien:
					<a rel="nofollow" href="https://www.e-recht24.de">
						eRecht24, Rechtsanwalt für Internetrecht Sören Siebert
					</a>
					Quellenangaben für die verwendeten Bilder und Grafiken: <br />
					Fotos: Sabrina Schindzielorz, Daniela Faber, Dominique Gartmann, Anita
					Trusheim, Sascha Ebeling
					<br />
					Text Idee: Daniela Faber
					<br />
					Gestaltung und Programmierung: yuupless digital Agentur (Sascha
					Ebeling & Sebastian Raßloff)
					<br/>
					<a
						href="https://yuupless.com/"
						target="_blank"
						without
						rel="noreferrer"
					>
						<Image
							src={yuupless}
							alt="yuupless digital agentur"
							height="30px"
						/>
					</a>
					<br />
				</p>

				<h2>Copyright / Haftungsausschluss:</h2>
				<p>
					Im Hinblick auf die technischen Eigenschaften des Internet kann keine
					Gewähr für die Authentizität, Richtigkeit und Vollständigkeit der im
					Internet zur Verfügung gestellten Informationen übernommen werden. Es
					wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der
					gegenständlichen Homepage und ihrer Inhalte übernommen. Jede Haftung
					für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von
					deren Ursachen, die aus der Benutzung oder Nichtverfügbarkeit der
					Daten und Informationen dieser Homepage erwachsen, wird, soweit
					rechtlich zulässig, ausgeschlossen. Hyperlinks auf diese Website sind
					willkommen. Jede Einbindung einzelner Seiten unseres Angebotes in
					fremde Frames ist zu unterlassen. Die Inhalte unserer Seiten wurden
					mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
					und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
					Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
					auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
					§§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
					verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
					überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
					Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
					Nutzung von informationen nach den allgemeinen Gesetzen bleiben
					hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
					Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
					Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
					Inhalte umgehend entfernen.
				</p>
				<h2>Urheberrecht:</h2>
				<p>
					Die durch die Seitenbetreiber erstellten Inhalte und Werke (sowohl
					Text als auch Bild) auf diesen Seiten unterliegen dem deutschen
					Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede
					Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
					der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
					Downloads und Kopien dieser Seite sind nur für den privaten, nicht
					kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite
					nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
					beachtet. insbesondere werden Inhalte Dritter als solche
					gekennzeichnet. Sollten sie trotzdem auf eine Urheberrechtsverletzung
					aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
					Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
					umgehend entfernen.
				</p>
				<h2>Haftung für Links: </h2>
				<p>
					Für Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
					Einfluss haben, können wir für diese fremden Inhalte keinerlei Gewähr
					übernehmen und eine Haftung jedweder Art ist ausgeschlossen. Für die
					Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
					Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
					Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
					Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
					erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
					ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
					zumutbar. Bei bekannt werden von Rechtsverletzungen werden wir
					derartige Links umgehend entfernen.
				</p>
			</Container>
		</React.Fragment>
	);
};

export default Imprint;
