import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const data = {
	title: "Finde den Weg in deine schöpferische Kraft",
	// title: "Meine Begleitung dient der Heilung in deine schöpferische Kraft. Gemeinsam finden wir deinen Herzensweg.",
	content:
		"Heilung der Ahnenlinie. Heilung alter Verletzungen, Schocks, Traumatas. Auflösen von limitierenden Glaubenssätzen. Meditation und Rituale als Unterstützung. Begleitung bei Kinderwunsch, während der Schwangerschaft und Geburt. Begleitung auf körperlicher, emotionaler und seelischer Ebene.",
};

const Section1 = () => {

	return (
		<section>
			<Container>
				<Col>
					<Row style={{ marginTop: "74px", textAlign: "center" }}>
						<h2 className="display-6">{data.title}</h2>
					</Row>
				</Col>
			</Container>
		</section>
	);
};

export default Section1;
