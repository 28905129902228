import React, { useState, useEffect } from "react";
// import styled from "styled-components";

function ReadingBar() {
	const [width, setWidth] = useState(0);

	const scrollHeight = () => {
		var el = document.documentElement,
			ScrollTop = el.scrollTop || document.body.scrollTop,
			ScrollHeight = el.scrollHeight || document.body.scrollHeight;

		var percent = (ScrollTop / (ScrollHeight - el.clientHeight)) * 100;

		setWidth(percent);
	};

	useEffect(() => {
		window.addEventListener("scroll", scrollHeight);
		return () => window.removeEventListener("scroll", scrollHeight);
	});

	return <div style={{ position: "fixed", height: "3px", borderRadius: "0px 0px 2px 0px", backgroundColor: "#d4b66b", width: width + "%", zIndex:'9999'}}></div>;
}
export default ReadingBar;
