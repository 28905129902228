import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, ButtonGroup } from "react-bootstrap";
import Course from "./Course";
import LinkComp from "./LinkComp";
import Loading from "./Loading";

const url = "https://backend.seinimlicht.de/wp-json/wp/v2/";

const Courses = () => {
	const [categories, setCategories] = useState();
	const [courses, setCourses] = useState();
	const [counter, setCounter] = useState(3);
	const [category, setCategory] = useState();
	const [coursesList, setCourseList] = useState([]);

	useEffect(() => {
		async function loadPosts() {
			// setBlogLoading(true)
			const response = await fetch(`${url}posts?per_page=100`);
			if (!response.ok) {
				// oups! something went wrong
				return;
			}
			const posts = await response.json();
			const filteredPosts = posts.filter(
				(article) =>
					article.categories[0] !== 8 &&
					//   article.categories[0] !== 6 &&
					article.categories[0] !== 9
			);
			setCourses(filteredPosts);
		}

		async function loadCategories() {
			const response = await fetch(`${url}categories?per_page=100`);
			if (!response.ok) {
				// oups! something went wrong
				return;
			}
			const categoriesList = await response.json();
			const filtered = categoriesList
				.filter(
					(category) =>
						category.id === 15 ||
						category.id === 6 ||
						category.id === 14 ||
						category.id === 13
				)
				.filter((category) => category.slug !== "uncategorized");
			setCategories(filtered);
			setCategory(filtered[0].id);
		}

		loadPosts();
		loadCategories();
	}, []);

	const lengthCategory = coursesList && coursesList.length;

	useEffect(() => {
		if (category != null && courses)
			setCourseList(
				courses.filter((course) => course.categories[0] === category)
			);
		if (category == null) setCourseList(courses);
	}, [category, courses]);

	const countHandler = () => {
		if (counter + 2 > lengthCategory) {
			setCounter(lengthCategory);
		} else {
			const newCounter = counter + 1;
			setCounter(newCounter);
		}
	};

	const lessHandler = () => {
		if (counter > 3) {
			setCounter(3);
		}
	};

	const selectCategoryHandler = (a) => {
		if (category === a) setCategory(null);
		else {
			setCategory(a);
			const listOfItems = courses.filter(
				(course) => course.categories[0] === a
			).length;
			if (listOfItems > counter) {
				setCounter(counter);
			}
			if (listOfItems < counter) {
				if (listOfItems > 3) {
					setCounter(listOfItems);
				} else {
					setCounter(3);
				}
			}
		}
	};

	return (
		<div id="coursesTop" className="gray-background">
			{categories && courses ? (
				<section id="coursesTop">
					<Container>
						<Row>
							<Col md={12}>
								<h2 className="display-6">
									COACHING | EVENTS | KURSE | Workshops
								</h2>
							</Col>
							{categories &&
								categories.map((a) => (
									<Col
										xs={6}
										sm={3}
										md={3}
										lg={3}
										xl={3}
										key={a.id}
										style={{ marginBottom: "20px" }}
										className="category-button"
									>
										<Button
											style={{ width: "100%" }}
											className={
												a.id === category
													? "btn-secondary active"
													: "btn-secondary"
											}
											onClick={() => selectCategoryHandler(a.id)}
										>
											{a.slug}{" "}
										</Button>
									</Col>
								))}
						</Row>
						<Row style={{ marginTop: "10px" }}>
							{coursesList &&
								coursesList.slice(0, counter).map((article) => (
									<Col
										key={article.id}
										md={12}
										style={{ marginBottom: "20px" }}
									>
										<Course course={article} />
									</Col>
								))}
							<div style={{ textAlign: "right" }}>
								<ButtonGroup>
									{counter > 3 && (
										<LinkComp target="coursesTop">
											<Button
												onClick={lessHandler}
												disabled={counter <= 3}
												className="btn-secondary minus-button"
											>
												-
											</Button>
										</LinkComp>
									)}
									{lengthCategory > counter && (
										<Button
											onClick={countHandler}
											disabled={lengthCategory === counter}
											className={
												counter <= 3
													? "btn-secondary plus-button-only"
													: "btn-secondary plus-button"
											}
										>
											+
										</Button>
									)}
								</ButtonGroup>
								{/* <div>
							{coursesList.length > 0 ? (
								<div>
									{counter} von {coursesList.length}
								</div>
							) : (
								<div>0 von {coursesList.length}</div>
							)}
						</div> */}
							</div>
						</Row>
					</Container>
				</section>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default Courses;
