import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// AGB
const Legal = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Container>
				<Link to="/">
					<p>zurück</p>
				</Link>
				<h1 className="display-6">RECHTLICHES</h1>
				<hr />
				<h2>IMPRESSUM</h2>
				<p style={{ color: "black" }}>
					Daniela Faber <br /> Bischofswang 4 <br /> D - 87672 Roßhaupten <br />
					<a href="mailto:info@seinimlicht.de">info@seinimlicht.de</a> <br />
					+49 176 47 161 073
				</p>
				<p>
					<span style={{ color: "black" }}>Rechtliches | </span>
					Meine Arbeit ersetzt keine Medikamente oder die Behandlung eines
					Arztes, Heilpraktikers, Therapeuten. Ich stelle weder Diagnosen noch
					gebe ich Heilversprechen ab. Der Klient handelt auf eigene
					Verantwortung. Jeder Sitzungs- und Kursteilnehmer haftet für sich.
				</p>
				<h2>HONORAR</h2>
				<p>
					<span style={{ color: "black" }}>Telefonisches Erstgespräch</span>,
					auch via Skype: ca. € 15,-- / halbe Stunde.
				</p>
				<p>
					<span style={{ color: "black" }}>Einzeltermin</span> , je Stunde €
					50,--
				</p>
				<p>
					<span style={{ color: "black" }}>Gruppen und Seminare</span>, die
					Preise entnehmen Sie bitte dem jeweiligen Termin. Sie werden vor
					Kursbeginn fällig und können überwiesen oder bei Kursantritt direkt
					bezahlt werden. Unterrichtsunterlagen und Skripte sind i.d.R. im Preis
					inbegriffen. Rücktritt bis spätestens 1 Woche vor Kursbeginn,
					ansonsten wird der volle Betrag fällig. Reduzierung des Honorars in
					besonderer Lebenssituation immer möglich. Bitte sprechen Sie mich
					darauf an.
				</p>
				<p>
					<span style={{ color: "black" }}>Rücktritt von Einzelterminen</span>
					bitte mindestens 24 Std. vorher, danach Berechnung der vollen 
					Leistung zu 100%.
				</p>
			</Container>
		</React.Fragment>
	);
};

export default Legal;
