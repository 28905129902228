import React from "react";
import {
	Carousel as CarouselBootstrap,
	Container,
	// Button,
	// Card,
} from "react-bootstrap";
import Trommel from "../images/Trommel_DIA.jpeg";
import Wasserritual from "../images/Wasserritual_DIA3.jpeg";
import oBuddha from "../images/3C1A8866.jpg";
// import Hueterin from "../images/IMG_5264x.jpeg";
import fire from "../images/Feuer_x2.jpg";
// import ContactModal from "./ContactModal";
// import pdf from "../data/2022_onepager_seinimlicht_wasser.pdf";
// import pdf from "../data/2023_onepager_seinimlicht_wasser_x3.pdf";

const Carousel = () => {
	return (
		<Container>
			<CarouselBootstrap interval={null}>
				{/* <CarouselBootstrap.Item>
					<img
						className="d-block w-100 carouselImg"
						src={Hueterin}
						alt="Hüter:in des Wassers"
					/>
					<CarouselBootstrap.Caption>
						<div
							style={{
								maxWidth: "500px",
								minWidth: "250px",
								marginRight: "auto",
								marginLeft: "auto",
							}}
						>
							<Card
								style={{
									padding: "10px",
									backgroundColor: "rgba(255, 255, 255, 0.9)",
								}}
							>
								<h2 className="lato-header">
									Ausbildung zur Hüterin & zum Hüter des Wasser’s 2023
								</h2>
								<div
									style={{
										maxWidth: "300px",
										marginRight: "auto",
										marginLeft: "auto",
									}}
								>
									<ContactModal
										subject="Ausbildung zur Hüter:in des Wasser's"
										classes="caption-buttons"
										btntitle="Jetzt anfragen"
									/>
									<Button
										className="caption-buttons"
										href={pdf}
										download
										style={{ marginTop: "2px", marginBottom: "40px" }}
									>
										Download Info PDF
									</Button>
								</div>
							</Card>
						</div>
					</CarouselBootstrap.Caption>
				</CarouselBootstrap.Item> */}
				<CarouselBootstrap.Item>
					<img
						className="d-block w-100 carouselImg"
						src={Trommel}
						alt="Trommel"
					/>
				</CarouselBootstrap.Item>
				<CarouselBootstrap.Item>
					<img className="d-block w-100 carouselImg" src={fire} alt="Trommel" />
				</CarouselBootstrap.Item>
				<CarouselBootstrap.Item>
					<img
						className="d-block w-100 carouselImg"
						src={Wasserritual}
						alt="Wasserritual"
					/>
				</CarouselBootstrap.Item>
				<CarouselBootstrap.Item>
					<img
						className="d-block w-100 carouselImg"
						src={oBuddha}
						alt="oBuddha"
					/>
				</CarouselBootstrap.Item>
			</CarouselBootstrap>
		</Container>
	);
};

export default Carousel;
