import React from "react";
import { Link } from "react-scroll";

const LinkComp = (props) => {
	return (
		<Link
			to={props.target}
			spy={true}
			smooth={true}
			hashSpy={true}
			offset={0}
			duration={400}
			delay={0}
			isDynamic={true}
			ignoreCancelEvents={false}
			// spyThrottle={500}
		>
			{props.children}
		</Link>
	);
};

export default LinkComp;
