import React, { useState } from "react";
import { Link } from "react-router-dom";

// import { sanitize } from "../utils/miscellaneous";
import {
	Modal,
	Form,
	Col,
	Button,
	Spinner as Loading,
} from "react-bootstrap";
import "../animation.css";

const MailchimpForm = ({ status, message, onValidated }) => {
	const [showForm, setShowForm] = useState(false);

	function FormModal(props) {
		const [error, setError] = useState(null);
		const [email, setEmail] = useState(null);
		const [firstName, setFirstName] = useState(null);
		const [lastName, setLastName] = useState(null);
		const [postalCode, setPostalCode] = useState(null);
		const [city, setCity] = useState(null);
		const [privacy, setPrivacy] = useState(false);

		/**
		 * Handle form submit.
		 *
		 * @return {{value}|*|boolean|null}
		 */

		const handleFormSubmit = () => {
			const isFormValidated = onValidated({
				EMAIL: email,
				VORNAME: firstName,
				NACHNAME: lastName,
				PLZ: postalCode,
				ORT: city,
			});
			// setModalShow(true);

			// On success return true
			return email && email.indexOf("@") > -1 && isFormValidated;
		};

		/**
		 * Handle Input Key Event.
		 *
		 * @param event
		 */
		const handleInputKeyEvent = (event) => {
			setError(null);
			// Number 13 is the "Enter" key on the keyboard
			if (event.keyCode === 13) {
				// Cancel the default action, if needed
				event.preventDefault();
				// Trigger the button element with a click
				handleFormSubmit();
			}
		};

		/**
		 * Extract message from string.
		 *
		 * @param {String} message
		 * @return {null|*}
		 */
		// const getMessage = (message) => {
		// 	if (!message) {
		// 		return null;
		// 	}
		// 	const result = message?.split("-") ?? null;
		// 	if ("0" !== result?.[0]?.trim()) {
		// 		return sanitize(message);
		// 	}
		// 	const formattedMessage = result?.[1]?.trim() ?? null;
		// 	return formattedMessage ? sanitize(formattedMessage) : null;
		// };

		const privacyLabel = () => {
			return (
				<div>
					Ja, ich habe die{" "}
					<Link
						style={{ color: "black", textDecoration: "underline" }}
						to="/privacy"
					>
						Datenschutzerklärung
					</Link>{" "}
					zur Kenntnis genommen. *
				</div>
			);
		};

		return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				// status
				// message
				// onValidated
			>
				<Modal.Header closeButton>
					{status === "error" || status === "success" ? (
						<Modal.Title id="contained-modal-title-vcenter">
							{"error" === status || error ? (
								<div>Hoppla, probiere es bitte nocheinmal</div>
							) : null}
							{"success" === status && "error" !== status && !error && (
								<div>Newsletter Anmeldung erfolgreich</div>
							)}
						</Modal.Title>
					) : (
						<Modal.Title id="contained-modal-title-vcenter">
							Anmeldung zu meinem Newsletter "LICHTBLICK"
						</Modal.Title>
					)}
				</Modal.Header>
				{status === "error" || status === "success" ? (
					<Modal.Body>
						<div className="min-h-42px">
							{"sending" === status ? <Loading /> : null}
							{"error" === status || error ? (
								<React.Fragment>
									{/* <div
										dangerouslySetInnerHTML={{
											__html: error || getMessage(message),
										}}
									/> */}
									<br />
									<div
										className="swal2-icon swal2-error swal2-animate-error-icon"
										style={{ display: "flex" }}
									>
										<div className="swal2-error-circular-line-left"></div>
										<span className="swal2-error-line-tip"></span>
										<span className="swal2-error-line-long"></span>
										<div className="swal2-error-ring"></div>
										<div className="swal2-x-mark-line-left"></div>
										<div className="swal2-x-mark-line-right"></div>
									</div>
									<br />
									<div>
										Das hat leider nicht geklappt. Vergewissere Dich bitte, ob
										Du die beiden Pflichtfelder „Email“ und „Vorname“ ausgefüllt
										und eine aktive Mailadresse angegeben hast. Danke.
									</div>
								</React.Fragment>
							) : null}
							{"success" === status && "error" !== status && !error && (
								<React.Fragment>
									<br />
									<div
										className="swal2-icon swal2-success swal2-animate-success-icon"
										style={{ display: "flex" }}
									>
										<div className="swal2-success-circular-line-left"></div>
										<span className="swal2-success-line-tip"></span>
										<span className="swal2-success-line-long"></span>
										<div className="swal2-success-ring"></div>
										<div className="swal2-success-fix"></div>
										<div className="swal2-success-circular-line-right"></div>
									</div>
									<br />
									{/* <div
										dangerouslySetInnerHTML={{ __html: sanitize(message) }}
									/> */}
									<div>
										Deine Anmeldung zum „LICHTBLICK“ war erfolgreich. Schön,
										dass Du dabei bist.
									</div>
								</React.Fragment>
							)}
						</div>
					</Modal.Body>
				) : (
					<Modal.Body>
						<Form.Group>
							<Form.Control
								onChange={(event) => setEmail(event.target.value ?? "")}
								type="email"
								placeholder="E-Mail-Address*"
								onKeyUp={(event) => handleInputKeyEvent(event)}
								style={{ marginBottom: "1rem" }}
							/>
							<Form.Control
								onChange={(event) => setFirstName(event.target.value ?? "")}
								type="text"
								placeholder="Vorname*"
								onKeyUp={(event) => handleInputKeyEvent(event)}
								style={{ marginBottom: "1rem" }}
							/>
							<Form.Control
								onChange={(event) => setLastName(event.target.value ?? "")}
								type="text"
								placeholder="Nachname"
								onKeyUp={(event) => handleInputKeyEvent(event)}
								style={{ marginBottom: "1rem" }}
							/>
							<Form.Control
								onChange={(event) => setPostalCode(event.target.value ?? "")}
								type="number"
								placeholder="Postleitzahl"
								onKeyUp={(event) => handleInputKeyEvent(event)}
								style={{ marginBottom: "1rem" }}
							/>
							<Form.Control
								onChange={(event) => setCity(event.target.value ?? "")}
								type="text"
								placeholder="Ort"
								onKeyUp={(event) => handleInputKeyEvent(event)}
								style={{ marginBottom: "1rem" }}
							/>
							<p>
								Gut zu wissen: Wenn Du an Heilkreisen und Zeremonien in Deiner
								Region interessiert bist, kannst Du freiwillig Deine PLZ und
								Deinen Wohnort mitangeben.
							</p>
							<Form.Check
								style={{
									fontSize: "14px",
									fontFamily: "Lato",
									marginBottom: "12px",
								}}
								required
								type="checkbox"
								label={privacyLabel()}
								onChange={() => setPrivacy(!privacy)}
							/>

							<Button
								disabled={!email || !privacy || !firstName}
								onClick={handleFormSubmit}
							>
								ANMELDEN
							</Button>
							<br />
							<Form.Label
								style={{
									fontSize: "12px",
									fontFamily: "Lato",
								}}
							>
								* Pflichtfelder
							</Form.Label>
						</Form.Group>
					</Modal.Body>
				)}
				<Modal.Footer>
					<Button onClick={props.onHide}>Schließen</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<div>
			<Col>
				<Button onClick={() => setShowForm(true)}>ANMELDEN</Button>
			</Col>

			<FormModal show={showForm} onHide={() => setShowForm(false)} />
		</div>
	);
};

export default MailchimpForm;
