import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import {
	animateScroll as scroll,
} from "react-scroll";

const ScrollToTop = () => {

	const scrollHandler = () => {
		scroll.scrollToTop({								
			duration: 400,
			delay: 0,
			spyThrottle: 100});
	};

	return (
		<div className="scroll-to-top-button" onClick={scrollHandler}>
            <FontAwesomeIcon focusable role="button" icon={faArrowUp} size="lg"/>
		</div>
	);
};

export default ScrollToTop;
