import React, { useState, useRef, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Image,
	Button,
	ButtonGroup,
} from "react-bootstrap";
import ReactAudioPlayer from "react-audio-player";
import LinkComp from "./LinkComp";
import Loading from "./Loading";

const url = "https://backend.seinimlicht.de/wp-json/wp/v2/";

const Podcast = (props) => {
	const [podcast, setPodcast] = useState();
	const [podcastSeries, setPodcastSeries] = useState();

	const [counter, setCounter] = useState(4);
	const [series, setSeries] = useState();

	const podcastRef = useRef(null);
	const bottomRef = useRef(null);

	useEffect(() => {
		async function loadPodcast() {
			// setPodcastLoading(true)
			const response = await fetch(`${url}podcast?per_page=100`);
			if (!response.ok) {
				// oups! something went wrong
				return;
			}
			const podcast = await response.json();
			setPodcast(podcast);
			// setPodcastLoading(false)
		}

		async function loadPodcastSeries() {
			const response = await fetch(`${url}series?per_page=100`);
			if (!response.ok) {
				// oups! something went wrong
				return;
			}
			const podcastSeries = await response.json();
			const newList = podcastSeries.filter((item) => item.slug !== "sein-im-licht-daniela-faber")

			setPodcastSeries(newList)
			setSeries(newList[0].id);
		}
		loadPodcast();
		loadPodcastSeries();
	}, []);

	let podcastList = [];

	if (series != null)
		podcastList = podcast && podcast.filter((b) => b.series[0] === series);

	if (series == null) podcastList = podcast && podcast;

	const countHandler = () => {
		const newCounter = counter + 2;
		setCounter(newCounter);
	};

	const lessHandler = () => {
		if (counter > 4) {
			const newCounter = 4;
			setCounter(newCounter);
		}
	};

	const selectCategoryHandler = (a) => {
		if (series === a) {
			setSeries(null);
		} else {
			setSeries(a);
			const listOfItems = props.podcast.filter(
				(podcast) => podcast.series[0] === a
			).length;
			if (listOfItems > counter) {
				setCounter(counter);
			}
			if (listOfItems < counter) {
				if (listOfItems > 4) {
					setCounter(listOfItems);
				} else {
					setCounter(4);
				}
			}
		}
	};

	function createContent(content) {
		return { __html: content };
	}

	const list =
	podcastSeries &&
	podcastSeries.sort(function (a, b) {
		return b.id - a.id;
	});
	
	return (
		<div ref={podcastRef}>
			{podcast && podcastSeries ? (
				<section>
					<Container>
						<Row>
							<Col md={12}>
								<h2 className="display-6">Podcast</h2>
								<hr />
								<Row>
									<div>
										<h5 className="latoHeader">Serien</h5>
										<div className="podcast-series-container">
											{list &&
												list.map((a) => (
													<Image
														key={a.id}
														className="podcastSeriesImg"
														style={
															a.id === series
																? { filter: "none", border: "1px solid" }
																: null
														}
														// src={a.description.split('"')[3]}
														src={a.image}
														onClick={() => selectCategoryHandler(a.id)}
														alt={`Podcast Serie - ${a.title}`}
													/>
												))}
										</div>
									</div>
								</Row>
								<Row>
									<h5 className="latoHeader">Folgen</h5>
									{props &&
										podcastList.slice(0, counter).map((episode) => (
											<Col md={6} key={episode.id}>
												<div
													style={{
														border: "1px solid",
														borderRadius: "5px",
														padding: "20px",
														marginBottom: "20px",
														boxShadow: "2px 2px 16px rgba(92, 92, 92, 0.15)",
													}}
												>
													<div
														dangerouslySetInnerHTML={createContent(
															episode.title.rendered
														)}
														className="latoHeader"
													/>
													<ReactAudioPlayer
														src={episode.meta.audio_file}
														controls
													/>
													<div
														dangerouslySetInnerHTML={createContent(
															episode.content.rendered
														)}
													/>
												</div>
											</Col>
										))}
								</Row>

								<div style={{ textAlign: "right" }}>
									<ButtonGroup>
										{counter > 4 && (
											<LinkComp target="podcast">
												<Button
													onClick={lessHandler}
													disabled={counter <= 4}
													className="btn-secondary minus-button"
												>
													-
												</Button>
											</LinkComp>
										)}
										{podcastList.length > counter && (
											<Button
												onClick={countHandler}
												disabled={podcastList.length === counter}
												className={
													counter <= 4
														? "btn-secondary plus-button-only"
														: "btn-secondary plus-button"
												}
											>
												+
											</Button>
										)}
									</ButtonGroup>
								</div>

								<Col md={3} sm={6} ref={bottomRef}>
									<Row style={{ fontSize: "24px" }}>
										<Col md={3} sm={3} xs={3}>
											<a
												href="https://open.spotify.com/show/0Bw9rZxCYJ9xYXFtXbrVQR?si=77ac539b2cd44216"
												target="_blank"
												rel="noreferrer"
											>
												<i className="fab fa-spotify podcast-buttons"></i>
											</a>
										</Col>
										<Col md={3} sm={3} xs={3}>
											<a
												href="https://podcasts.apple.com/de/podcast/sein-im-licht-daniela-faber/id1566601234"
												target="_blank"
												rel="noreferrer"
											>
												<i className="fab fa-apple podcast-buttons"></i>
											</a>
										</Col>
										<Col md={3} sm={3} xs={3}>
											<a
												href="https://podcasts.google.com/feed/aHR0cHM6Ly9iYWNrZW5kLnNlaW5pbWxpY2h0LmRlL2ZlZWQvcG9kY2FzdA"
												target="_blank"
												rel="noreferrer"
											>
												<i className="fab fa-google podcast-buttons"></i>
											</a>
										</Col>
									</Row>
								</Col>
							</Col>
						</Row>
					</Container>
				</section>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default Podcast;
