import React, { useState } from "react";
import ContactModal from "./ContactModal";
import ReactDOMServer from "react-dom/server";
import { Card, Image, Col } from "react-bootstrap";

const Course = (props) => {
	const [showMore, setShowMore] = useState(false);

	function createContent(content) {
		return { __html: content };
	}

	const showMoreHandler = () => {
		if (showMore === true) {
			props.showMore();
		}
		setShowMore(!showMore);
	};

	let title = " ";
	if (props.course) {
		try {
			title = ReactDOMServer.renderToString(
				createContent(props.course.title.rendered)["__html"]
			)
				.replace(/&amp;#8222;/g, "")
				.replace(/&amp;#8220;/g, "");
		} catch {
			title = props.course.title.rendered;
		}
	}

	const content = props.course.content.rendered
		.replace(/<img[^>]*>/g, "")
		.replace(/<p>&nbsp;<\/p>/g, "")
		.replace(/<p><\/p>/g, "");

	let showLessContent = content.slice(0, 500)

	showLessContent = showLessContent.substr(0, Math.min(showLessContent.length, showLessContent.lastIndexOf(" "))) + " ..."

	return (
		<React.Fragment>
			<Col>
				{props && props.course && (
					<Card style={{ border: "1px solid" }}>
						<Card.Body>
							<Card.Title>
								<div className="latoHeader">{title}</div>
							</Card.Title>
							{props &&
							props.course.yoast_head_json &&
							props.course.yoast_head_json.og_image &&
							props.course.yoast_head_json.og_image[0] &&
							props.course.yoast_head_json.og_image[0].url ? (
								<Image
									src={props.course.yoast_head_json.og_image[0].url}
									style={{ height: "50px" }}
								/>
							) : null}
							<div>
								{content && (
									<div>
										{!showMore ? (
											<span>
												<span
													dangerouslySetInnerHTML={createContent(
														showLessContent
													)}
												/>
												<p
													style={{
														textAlign: "right",
														color: "#d4b66b",
														margin: "0",
													}}
													className="show-more"
													onClick={() => showMoreHandler()}
												>
													weiterlesen
												</p>
											</span>
										) : (
											<div>
												<span
													dangerouslySetInnerHTML={createContent(content)}
												/>
												<ContactModal
													subject={title}
													classes="btn-secondary"
													btntitle="Anfragen"
												/>
											</div>
										)}
									</div>
								)}
							</div>
						</Card.Body>
					</Card>
				)}
			</Col>
		</React.Fragment>
	);
};

export default Course;
