import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// AGB
const Privacy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Container>
				<Link to="/">
					<p>zurück</p>
				</Link>
				<h1>Datenschutz&shy;erkl&auml;rung</h1>
				<h2>1. Datenschutz auf einen Blick</h2>
				<h3>Allgemeine Hinweise</h3>{" "}
				<p>
					Die folgenden Hinweise geben einen einfachen &Uuml;berblick
					dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
					diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
					denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
					Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
					unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
				</p>
				<h3>Datenerfassung auf dieser Website</h3>{" "}
				<h4>
					Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
				</h4>{" "}
				<p>
					Die Datenverarbeitung auf dieser Website erfolgt durch den
					Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
					&bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
					Datenschutzerkl&auml;rung entnehmen.
				</p>{" "}
				<h4>Wie erfassen wir Ihre Daten?</h4>{" "}
				<p>
					Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
					mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
					in ein Kontaktformular eingeben.
				</p>{" "}
				<p>
					Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
					Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
					technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
					Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
					automatisch, sobald Sie diese Website betreten.
				</p>{" "}
				<h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{" "}
				<p>
					Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
					der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
					Analyse Ihres Nutzerverhaltens verwendet werden.
				</p>{" "}
				<h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{" "}
				<p>
					Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
					Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
					personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
					Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
					Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
					k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft
					widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
					Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
					personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
					Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
				</p>{" "}
				<p>
					Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
					sich jederzeit an uns wenden.
				</p>
				<h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>{" "}
				<p>
					Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
					ausgewertet werden. Das geschieht vor allem mit sogenannten
					Analyseprogrammen.
				</p>{" "}
				<p>
					Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
					der folgenden Datenschutzerkl&auml;rung.
				</p>
				<h2>2. Hosting</h2>
				<h3>IONOS</h3>{" "}
				<p>
					Wir hosten unsere Website bei IONOS SE. Anbieter ist die IONOS SE,
					Elgendorfer Str. 57, 56410 Montabaur (nachfolgend IONOS). Wenn Sie
					unsere Website besuchen, erfasst IONOS verschiedene Logfiles inklusive
					Ihrer IP-Adressen. Details entnehmen Sie der Datenschutzerkl&auml;rung
					von IONOS:{" "}
					<a
						href="https://www.ionos.de/terms-gtc/terms-privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.ionos.de/terms-gtc/terms-privacy
					</a>
					.
				</p>{" "}
				<p>
					Die Verwendung von IONOS erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
					f DSGVO. Wir haben ein berechtigtes Interesse an einer m&ouml;glichst
					zuverl&auml;ssigen Darstellung unserer Website. Sofern eine
					entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
					ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
					&sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
					Cookies oder den Zugriff auf Informationen im Endger&auml;t des
					Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
					Die Einwilligung ist jederzeit widerrufbar.
				</p>
				<h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
				<h3>Datenschutz</h3>{" "}
				<p>
					Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
					Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
					vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
					sowie dieser Datenschutzerkl&auml;rung.
				</p>{" "}
				<p>
					Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
					Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
					pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
					Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
					wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
					Zweck das geschieht.
				</p>{" "}
				<p>
					Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
					(z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
					aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
					durch Dritte ist nicht m&ouml;glich.
				</p>
				<h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
				<p>
					Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
					Website ist:
				</p>{" "}
				<p>
					Daniela Faber
					<br />
					Bischofswang 4<br />
					D - 87672 Ro&szlig;haupten
					<br />
					info@seinimlicht.de
					<br />
					+49 176 47 161 073
				</p>
				<p>
					Telefon: +49 176 47 161 073
					<br />
					E-Mail: info@seinimlicht.de
				</p>
				<p>
					Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
					Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
					Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
					E-Mail-Adressen o. &Auml;.) entscheidet.
				</p>
				<h3>Speicherdauer</h3>{" "}
				<p>
					Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
					Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
					bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt.
					Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
					Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
					gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen
					Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten
					haben (z.&nbsp;B. steuer- oder handelsrechtliche
					Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
					L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
				</p>
				<h3>
					Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
					dieser Website
				</h3>{" "}
				<p>
					Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
					wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit.
					a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
					Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
					einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung
					personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung
					au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern
					Sie in die Speicherung von Cookies oder in den Zugriff auf
					Informationen in Ihr Endger&auml;t (z.&nbsp;B. via
					Device-Fingerprinting) eingewilligt haben, erfolgt die
					Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1
					TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
					Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher
					Ma&szlig;nahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage
					des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre
					Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung
					erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
					Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
					Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die
					jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den
					folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.
				</p>
				<h3>
					Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
				</h3>{" "}
				<p>
					Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
					oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
					diese Tools aktiv sind, k&ouml;nnen Ihre personenbezogene Daten in
					diese Drittstaaten &uuml;bertragen und dort verarbeitet werden. Wir
					weisen darauf hin, dass in diesen L&auml;ndern kein mit der EU
					vergleichbares Datenschutzniveau garantiert werden kann.
					Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene
					Daten an Sicherheitsbeh&ouml;rden herauszugeben, ohne dass Sie als
					Betroffener hiergegen gerichtlich vorgehen k&ouml;nnten. Es kann daher
					nicht ausgeschlossen werden, dass US-Beh&ouml;rden (z.&nbsp;B.
					Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
					&Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft
					speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten keinen
					Einfluss.
				</p>
				<h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{" "}
				<p>
					Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
					ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
					bereits erteilte Einwilligung jederzeit widerrufen. Die
					Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
					Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
				</p>
				<h3>
					Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
					sowie gegen Direktwerbung (Art. 21 DSGVO)
				</h3>{" "}
				<p>
					WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
					F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE
					SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
					IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
					F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE
					JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
					ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH
					EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
					MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
					SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN,
					DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
					VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG
					VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
				</p>{" "}
				<p>
					WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
					BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
					VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
					DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
					SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
					WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
					MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
					ABS. 2 DSGVO).
				</p>
				<h3>
					Beschwerde&shy;recht bei der zust&auml;ndigen
					Aufsichts&shy;beh&ouml;rde
				</h3>{" "}
				<p>
					Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
					Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
					insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts,
					ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
					Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
					anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
				</p>
				<h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>{" "}
				<p>
					Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
					oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
					sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
					Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
					&Uuml;bertragung der Daten an einen anderen Verantwortlichen
					verlangen, erfolgt dies nur, soweit es technisch machbar ist.
				</p>
				<h3>Auskunft, L&ouml;schung und Berichtigung</h3>{" "}
				<p>
					Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
					das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
					personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
					Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
					L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
					personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
				</p>
				<h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{" "}
				<p>
					Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
					personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
					jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
					Verarbeitung besteht in folgenden F&auml;llen:
				</p>{" "}
				<ul>
					{" "}
					<li>
						Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
						personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
						Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
						Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
						Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
					</li>{" "}
					<li>
						Wenn die Verarbeitung Ihrer personenbezogenen Daten
						unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
						L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
						verlangen.
					</li>{" "}
					<li>
						Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
						sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
						Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
						L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
						personenbezogenen Daten zu verlangen.
					</li>{" "}
					<li>
						Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
						haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
						vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
						&uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
						Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
					</li>{" "}
				</ul>{" "}
				<p>
					Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
					eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
					Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
					Geltendmachung, Aus&uuml;bung oder Verteidigung von
					Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
					nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
					wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union
					oder eines Mitgliedstaats verarbeitet werden.
				</p>
				<h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
				<p>
					Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
					Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
					angeforderter Werbung und Informationsmaterialien wird hiermit
					widersprochen. Die Betreiber der Seiten behalten sich
					ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten
					Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
				</p>
				<h2>4. Datenerfassung auf dieser Website</h2>
				<h3>Cookies</h3>{" "}
				<p>
					Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;.
					Cookies sind kleine Textdateien und richten auf Ihrem Endger&auml;t
					keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die
					Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente
					Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden
					nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
					bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst
					l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch Ihren
					Webbrowser erfolgt.
				</p>{" "}
				<p>
					Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem
					Endger&auml;t gespeichert werden, wenn Sie unsere Seite betreten
					(Third-Party-Cookies). Diese erm&ouml;glichen uns oder Ihnen die
					Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.&nbsp;B.
					Cookies zur Abwicklung von Zahlungsdienstleistungen).
				</p>{" "}
				<p>
					Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
					technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
					funktionieren w&uuml;rden (z.&nbsp;B. die Warenkorbfunktion oder die
					Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
					auszuwerten&nbsp;oder Werbung anzuzeigen.
				</p>{" "}
				<p>
					Cookies, die zur Durchf&uuml;hrung des elektronischen
					Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
					erw&uuml;nschter Funktionen (z.&nbsp;B. f&uuml;r die
					Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B.
					Cookies zur Messung des Webpublikums) erforderlich sind (notwendige
					Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
					gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
					Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
					notwendigen Cookies zur technisch fehlerfreien und optimierten
					Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
					Speicherung von Cookies und vergleichbaren
					Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung
					ausschlie&szlig;lich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
					lit. a DSGVO und &sect; 25 Abs. 1 TTDSG); die Einwilligung ist
					jederzeit widerrufbar.
				</p>{" "}
				<p>
					Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
					Setzen von Cookies informiert werden und Cookies nur im Einzelfall
					erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder
					generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
					Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der
					Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website
					eingeschr&auml;nkt sein.
				</p>{" "}
				<p>
					Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
					werden, werden wir Sie hier&uuml;ber im Rahmen dieser
					Datenschutzerkl&auml;rung gesondert informieren und ggf. eine
					Einwilligung abfragen.
				</p>
				<h3>Server-Log-Dateien</h3>{" "}
				<p>
					Der Provider der Seiten erhebt und speichert automatisch Informationen
					in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
					&uuml;bermittelt. Dies sind:
				</p>{" "}
				<ul>
					{" "}
					<li>Browsertyp und Browserversion</li>{" "}
					<li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
					<li>Hostname des zugreifenden Rechners</li>{" "}
					<li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
				</ul>{" "}
				<p>
					Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
					nicht vorgenommen.
				</p>{" "}
				<p>
					Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
					lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
					der technisch fehlerfreien Darstellung und der Optimierung seiner
					Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst
					werden.
				</p>
				<h3>Kontaktformular</h3>{" "}
				<p>
					Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
					Angaben aus dem Anfrageformular inklusive der von Ihnen dort
					angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r
					den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
					wir nicht ohne Ihre Einwilligung weiter.
				</p>{" "}
				<p>
					Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
					lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
					Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
					vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
					&uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
					berechtigten Interesse an der effektiven Bearbeitung der an uns
					gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
					Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
					wurde; die Einwilligung ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
					uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
					Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
					entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
					Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere
					Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
				</p>
				<h2>5. Soziale Medien</h2>
				<h3>Instagram Plugin</h3>{" "}
				<p>
					Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden.
					Diese Funktionen werden angeboten durch die Meta Platforms Ireland
					Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
					integriert.
				</p>{" "}
				<p>
					Wenn Sie in Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen Sie
					durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit
					Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch
					dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin,
					dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
					&uuml;bermittelten Daten sowie deren Nutzung durch Instagram erhalten.
				</p>{" "}
				<p>
					Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
					Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
					Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den
					Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
					wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
					Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die
					Einwilligung die Speicherung von Cookies oder den Zugriff auf
					Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
					Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
					ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten
					auf unserer Website erfasst und an Facebook bzw. Instagram
					weitergeleitet werden, sind wir und die Meta Platforms Ireland
					Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
					gemeinsam f&uuml;r diese Datenverarbeitung verantwortlich (Art. 26
					DSGVO). Die gemeinsame Verantwortlichkeit beschr&auml;nkt sich dabei
					ausschlie&szlig;lich auf die Erfassung der Daten und deren Weitergabe
					an Facebook bzw. Instagram. Die nach der Weiterleitung erfolgende
					Verarbeitung durch Facebook bzw. Instagram ist nicht Teil der
					gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
					Verpflichtungen wurden in einer Vereinbarung &uuml;ber gemeinsame
					Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie
					unter:{" "}
					<a
						href="https://www.facebook.com/legal/controller_addendum"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.facebook.com/legal/controller_addendum
					</a>
					. Laut dieser Vereinbarung sind wir f&uuml;r die Erteilung der
					Datenschutzinformationen beim Einsatz des Facebook- bzw.
					Instagram-Tools und f&uuml;r die datenschutzrechtlich sichere
					Implementierung des Tools auf unserer Website verantwortlich. F&uuml;r
					die Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook
					verantwortlich. Betroffenenrechte (z.&nbsp;B. Auskunftsersuchen)
					hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten
					k&ouml;nnen Sie direkt bei Facebook geltend machen. Wenn Sie die
					Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese
					an Facebook weiterzuleiten.
				</p>{" "}
				<p>
					Die Daten&uuml;bertragung in die USA wird auf die
					Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
					finden Sie hier:{" "}
					<a
						href="https://www.facebook.com/legal/EU_data_transfer_addendum"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.facebook.com/legal/EU_data_transfer_addendum
					</a>
					,{" "}
					<a
						href="https://help.instagram.com/519522125107875"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://help.instagram.com/519522125107875
					</a>{" "}
					und{" "}
					<a
						href="https://de-de.facebook.com/help/566994660333381"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://de-de.facebook.com/help/566994660333381
					</a>
					.
				</p>{" "}
				<p>
					Weitere Informationen hierzu finden Sie in der
					Datenschutzerkl&auml;rung von Instagram:{" "}
					<a
						href="https://instagram.com/about/legal/privacy/"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://instagram.com/about/legal/privacy/
					</a>
					.
				</p>
				<h2>6. Analyse-Tools und Werbung</h2>
				<h3>IONOS WebAnalytics</h3>{" "}
				<p>
					Diese Website nutzt die Analysedienste von IONOS WebAnalytics (im
					Folgenden: IONOS). Anbieter ist die 1&amp;1 IONOS SE, Elgendorfer
					Stra&szlig;e 57, D &ndash; 56410 Montabaur. Im Rahmen der Analysen mit
					IONOS k&ouml;nnen u.&nbsp;a. Besucherzahlen und &ndash;verhalten
					(z.&nbsp;B. Anzahl der Seitenaufrufe, Dauer eines Webseitenbesuchs,
					Absprungraten), Besucherquellen (d. h., von welcher Seite der Besucher
					kommt), Besucherstandorte sowie technische Daten (Browser- und
					Betriebssystemversionen) analysiert werden. Zu diesem Zweck speichert
					IONOS insbesondere folgende Daten:
				</p>{" "}
				<ul>
					{" "}
					<li>Referrer (zuvor besuchte Webseite)</li>{" "}
					<li>angeforderte Webseite oder Datei</li>{" "}
					<li>Browsertyp und Browserversion</li>{" "}
					<li>verwendetes Betriebssystem</li>{" "}
					<li>verwendeter Ger&auml;tetyp</li> <li>Uhrzeit des Zugriffs</li>{" "}
					<li>
						IP-Adresse in anonymisierter Form (wird nur zur Feststellung des
						Orts des Zugriffs verwendet)
					</li>{" "}
				</ul>{" "}
				<p>
					Die Datenerfassung erfolgt laut IONOS vollst&auml;ndig anonymisiert,
					sodass sie nicht zu einzelnen Personen zur&uuml;ckverfolgt werden
					kann. Cookies werden von IONOS WebAnalytics nicht gespeichert.
				</p>{" "}
				<p>
					Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
					Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
					Interesse an der statistischen Analyse des Nutzerverhaltens, um sowohl
					sein Webangebot als auch seine Werbung zu optimieren. Sofern eine
					entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
					ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
					&sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
					Cookies oder den Zugriff auf Informationen im Endger&auml;t des
					Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
					Die Einwilligung ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Weitere Informationen zur Datenerfassung und Verarbeitung durch IONOS
					WebAnalytics entnehmen Sie der Datenschutzerklaerung von IONOS unter
					folgendem Link:
				</p>{" "}
				<p>
					<a
						href="https://www.ionos.de/terms-gtc/index.php?id=6"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.ionos.de/terms-gtc/index.php?id=6
					</a>
				</p>
				<h3>WP Statistics</h3>{" "}
				<p>
					Diese Website nutzt das Analysetool WP Statistics, um Besucherzugriffe
					statistisch auszuwerten. Anbieter ist Veronalabs, ARENCO Tower, 27th
					Floor, Dubai Media City, Dubai, Dubai 23816, UAE (
					<a
						href="https://veronalabs.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://veronalabs.com
					</a>
					).
				</p>{" "}
				<p>
					Mit WP Statistics k&ouml;nnen wir die Nutzung unserer Website
					analysieren. WP Statistics erfasst dabei u.&nbsp;a. Logdateien
					(IP-Adresse, Referrer, verwendete Browser, Herkunft des Nutzers,
					verwendete Suchmaschine) und Aktionen, die die Websitebesucher auf der
					Seite get&auml;tigt haben (z.&nbsp;B. Klicks und Ansichten).
				</p>{" "}
				<p>
					Die mit WP Statistics erfassten Daten werden ausschlie&szlig;lich auf
					unserem eigenen Server gespeichert.
				</p>{" "}
				<p>
					Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs.
					1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an der
					anonymisierten Analyse des Nutzerverhaltens, um sowohl unser
					Webangebot als auch unsere Werbung zu optimieren. Sofern eine
					entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
					ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
					&sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
					Cookies oder den Zugriff auf Informationen im Endger&auml;t des
					Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
					Die Einwilligung ist jederzeit widerrufbar.
				</p>
				<h2>7. Newsletter</h2>
				<h3>Newsletter&shy;daten</h3>{" "}
				<p>
					Wenn Sie den auf der Website angebotenen Newsletter beziehen
					m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse sowie
					Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass
					Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem
					Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht
					bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
					ausschlie&szlig;lich f&uuml;r den Versand der angeforderten
					Informationen und geben diese nicht an Dritte weiter.
				</p>{" "}
				<p>
					Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
					Daten erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung
					(Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
					Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
					Versand des Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa
					&uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter. Die
					Rechtm&auml;&szlig;igkeit der bereits erfolgten
					Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
				</p>{" "}
				<p>
					Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
					Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
					uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
					Abbestellung des Newsletters oder nach Zweckfortfall aus der
					Newsletterverteilerliste gel&ouml;scht. Wir behalten uns vor,
					E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen
					im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
					DSGVO zu l&ouml;schen oder zu sperren.
				</p>{" "}
				<p>
					Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben
					hiervon unber&uuml;hrt.
				</p>{" "}
				<p>
					Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
					E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
					einer Blacklist gespeichert, sofern dies zur Verhinderung
					k&uuml;nftiger Mailings erforderlich ist. Die Daten aus der Blacklist
					werden nur f&uuml;r diesen Zweck verwendet und nicht mit anderen Daten
					zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch
					unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim
					Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6
					Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich
					nicht befristet.{" "}
					<strong>
						Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre
						Interessen unser berechtigtes Interesse &uuml;berwiegen.
					</strong>
				</p>
				<h3>Mailchimp</h3>
				<p>
					Wir verwenden für den Versand unseres Newsletters Mailchimp von The
					Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000,
					Atlanta, GA 30308 USA (Mailchimp). Dies ermöglicht uns, mit Abonnenten
					direkt in Kontakt zu treten. Zusätzlich analysieren wir Ihr
					Nutzungsverhalten, um unser Angebot zu optimieren.
				</p>
				<p>
					Dafür geben wir folgende personenbezogene Daten an Mailchimp weiter:{" "}
					<br /> E-Mail [Unsere E-Mail-Aussendungen beinhalten einen Link, mit
					dem Sie Ihre personenbezogenen Daten aktualisieren können.]
				</p>
				<p>
					Mailchimp ist Empfänger Ihrer personenbezogenen Daten und als
					Auftragsverarbeiter für uns tätig, soweit es um den Verand unseres
					Newsletters geht. Die Verarbeitung der unter diesem Abschnitt
					angegebenen Daten ist weder gesetzlich noch vertraglich
					vorgeschrieben. Ohne Ihre Einwilligung und die Übermittlung Ihrer
					personenbezogenen Daten, können wir keinen Newsletter an Sie
					aussenden.
				</p>
				<p>
					Zusätzlich werden von Mailchimp folgende personenbezogene Daten mit
					Hilfe von Cookies und anderen Tracking-Methoden gesammelt:
					Informationen über Ihr Endgerät (IP-Adresse, Geräteinformationen,
					Betriebssystem, Browser-ID, Informationen über die Anwendung mit der
					Sie Ihre E-Mails lesen und weitere Informationen über Hardware und
					Internetverbindung. Darüber hinaus werden Nutzungsdaten gesammelt wie
					Datum und Uhrzeit, wann Sie die E-Mail / Kampagne geöffnet haben und
					Browseraktivitäten (z.B. welche E-Mails / Webseiten geöffnet wurden).
					Mailchimp benötigt diese Daten, um die Sicherheit und Zuverlässigkeit
					der Systeme, die Einhaltung der Nutzungsbedingungen und die Vermeidung
					von Missbrauch zu gewährleisten. Dies entspricht dem berechtigten
					Interesse Mailchimp (gem. Art. 6 Abs. 1 lit. f DSGVO) und dient der
					Vertragsdurchführung (gem. Art. 6 Abs. 1 lit. b DSGVO). Weiter werden
					von Mailchimp Leistungsdaten ausgewertet, wie die
					Auslieferungsstatistik von E-Mails und weitere Kommunikationsdaten.
					Diese Informationen werden verwendet, um Nutzungs- und
					Leistungsstatistiken der Dienste zu erstellen.
				</p>
				<p>
					Mailchimp sammelt zusätzlich Informationen aus anderen Quellen über
					Sie. In einem nicht näher bestimmten Zeitraum und Umfang, werden
					personenbezogene Daten über soziale Medien und weiteren Drittanbietern
					von Daten gesammelt. Wir haben auf diesen Prozess keinen Einfluss.
				</p>
				<p>
					Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten
					gegenüber Mailchimp finden Sie unter:
					<a href="https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts">
						https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts
					</a>
				</p>
				<p>
					Rechtsgrundlage für diese Verarbeitungen ist Ihre Einwilligung gemäß
					Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung zur Verarbeitung Ihrer
					personenbezogenen Daten können Sie jederzeit widerrufen. In allen
					Aussendungen findet sich ein entsprechender Link. Außerdem kann der
					Widerruf über die angegebenen Kontaktmöglichkeiten erfolgen. Durch
					Erklärung des Widerrufs, wird die Rechtmäßigkeit, der bisher erfolgten
					Verarbeitung nicht berührt.
				</p>
				<p>
					Ihre Daten werden solange verarbeitet, wie eine entsprechende
					Einwilligung vorliegt. Abgesehen davon, werden diese nach Beendigung
					des Vertrages zwischen uns und Mailchimp gelöscht, es sei denn, dass
					gesetzliche Vorgaben eine weitere Speicherung erforderlich machen.
				</p>
				<p>
					Mailchimp hat Compliance-Maßnahmen für internationale
					Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten
					Aktivitäten, bei denen Stripe personenbezogene Daten von natürlichen
					Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den
					EU-Standardvertragsklauseln (SCCs). Weitere Informationen finden Sie
					unter:{" "}
					<a href="https://mailchimp.com/legal/data-processing-addendum/">
						https://mailchimp.com/legal/data-processing-addendum/
					</a>
				</p>
				<h2>8. Plugins und Tools</h2>
				<h3>YouTube</h3>{" "}
				<p>
					Diese Website bindet Videos der Website YouTube ein. Betreiber der
					Website ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon
					House, Barrow Street, Dublin 4, Irland.
				</p>{" "}
				<p>
					Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube
					eingebunden ist, wird eine Verbindung zu den Servern von YouTube
					hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer
					Seiten Sie besucht haben.
				</p>{" "}
				<p>
					Des Weiteren kann YouTube verschiedene Cookies auf Ihrem Endger&auml;t
					speichern oder vergleichbare Technologien zur Wiedererkennung
					verwenden (z.&nbsp;B. Device-Fingerprinting). Auf diese Weise kann
					YouTube Informationen &uuml;ber Besucher dieser Website erhalten.
					Diese Informationen werden u.&nbsp;a. verwendet, um Videostatistiken
					zu erfassen, die Anwenderfreundlichkeit zu verbessern und
					Betrugsversuchen vorzubeugen.
				</p>{" "}
				<p>
					Wenn Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen
					Sie YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil
					zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
					YouTube-Account ausloggen.
				</p>{" "}
				<p>
					Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
					Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
					Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
					entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
					ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
					&sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
					Cookies oder den Zugriff auf Informationen im Endger&auml;t des
					Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
					Die Einwilligung ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
					Datenschutzerkl&auml;rung von YouTube unter:{" "}
					<a
						href="https://policies.google.com/privacy?hl=de"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://policies.google.com/privacy?hl=de
					</a>
					.
				</p>
				<h3>Vimeo</h3>{" "}
				<p>
					Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die
					Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.
				</p>{" "}
				<p>
					Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten
					besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt.
					Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie
					besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch
					dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei
					Vimeo besitzen. Die von Vimeo erfassten Informationen werden an den
					Vimeo-Server in den USA &uuml;bermittelt.
				</p>{" "}
				<p>
					Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, erm&ouml;glichen Sie
					Vimeo, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil
					zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
					Vimeo-Account ausloggen.
				</p>{" "}
				<p>
					Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies bzw.
					vergleichbare Wiedererkennungstechnologien (z.&nbsp;B.
					Device-Fingerprinting).
				</p>{" "}
				<p>
					Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
					Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
					Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
					entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
					ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
					&sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
					Cookies oder den Zugriff auf Informationen im Endger&auml;t des
					Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
					Die Einwilligung ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Die Daten&uuml;bertragung in die USA wird auf die
					Standardvertragsklauseln der EU-Kommission sowie nach Aussage von
					Vimeo auf &bdquo;berechtigte Gesch&auml;ftsinteressen&ldquo;
					gest&uuml;tzt. Details finden Sie hier:{" "}
					<a
						href="https://vimeo.com/privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://vimeo.com/privacy
					</a>
					.
				</p>{" "}
				<p>
					Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
					Datenschutzerkl&auml;rung von Vimeo unter:{" "}
					<a
						href="https://vimeo.com/privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://vimeo.com/privacy
					</a>
					.
				</p>
				<h3>Google Web Fonts</h3>{" "}
				<p>
					Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
					genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
					einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in
					ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
				</p>{" "}
				<p>
					Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
					den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
					dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen
					wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art.
					6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
					Interesse an der einheitlichen Darstellung des Schriftbildes auf
					seiner Website. Sofern eine entsprechende Einwilligung abgefragt
					wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
					Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die
					Einwilligung die Speicherung von Cookies oder den Zugriff auf
					Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
					Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
					ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine
					Standardschrift von Ihrem Computer genutzt.
				</p>{" "}
				<p>
					Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
					<a
						href="https://developers.google.com/fonts/faq"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://developers.google.com/fonts/faq
					</a>{" "}
					und in der Datenschutzerkl&auml;rung von Google:{" "}
					<a
						href="https://policies.google.com/privacy?hl=de"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://policies.google.com/privacy?hl=de
					</a>
					.
				</p>
				<h3>Font Awesome</h3>{" "}
				<p>
					Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten und
					Symbolen Font Awesome. Anbieter ist die Fonticons, Inc., 6 Porter Road
					Apartment 3R, Cambridge, Massachusetts, USA.
				</p>{" "}
				<p>
					Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten
					Fonts in ihren Browsercache, um Texte, Schriftarten und Symbole
					korrekt anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete
					Browser Verbindung zu den Servern von Font Awesome aufnehmen.
					Hierdurch erlangt Font Awesome Kenntnis dar&uuml;ber, dass &uuml;ber
					Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von Font
					Awesome erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir
					haben ein berechtigtes Interesse an der einheitlichen Darstellung des
					Schriftbildes auf unserer Website. Sofern eine entsprechende
					Einwilligung abgefragt wurde, erfolgt die Verarbeitung
					ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
					&sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
					Cookies oder den Zugriff auf Informationen im Endger&auml;t des
					Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
					Die Einwilligung ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Wenn Ihr Browser Font Awesome nicht unterst&uuml;tzt, wird eine
					Standardschrift von Ihrem Computer genutzt.
				</p>{" "}
				<p>
					Weitere Informationen zu Font Awesome finden Sie&nbsp;und in der
					Datenschutzerkl&auml;rung von Font Awesome unter:{" "}
					<a
						href="https://fontawesome.com/privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://fontawesome.com/privacy
					</a>
					.
				</p>
				<h3>Google Maps</h3>{" "}
				<p>
					Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
					Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow
					Street, Dublin 4, Irland.
				</p>{" "}
				<p>
					Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
					IP-Adresse zu speichern. Diese Informationen werden in der Regel an
					einen Server von Google in den USA &uuml;bertragen und dort
					gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese
					Daten&uuml;bertragung. Wenn Google Maps aktiviert ist, kann Google zum
					Zwecke der einheitlichen Darstellung der Schriftarten Google Web Fonts
					verwenden. Beim Aufruf von Google Maps l&auml;dt Ihr Browser die
					ben&ouml;tigten Web Fonts in ihren Browsercache, um Texte und
					Schriftarten korrekt anzuzeigen.
				</p>{" "}
				<p>
					Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
					Darstellung unserer Online-Angebote und an einer leichten
					Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
					stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
					DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
					erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6
					Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die
					Einwilligung die Speicherung von Cookies oder den Zugriff auf
					Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
					Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
					ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Die Daten&uuml;bertragung in die USA wird auf die
					Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
					finden Sie hier:{" "}
					<a
						href="https://privacy.google.com/businesses/gdprcontrollerterms/"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://privacy.google.com/businesses/gdprcontrollerterms/
					</a>{" "}
					und{" "}
					<a
						href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
					</a>
					.
				</p>{" "}
				<p>
					Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
					Datenschutzerkl&auml;rung von Google:{" "}
					<a
						href="https://policies.google.com/privacy?hl=de"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://policies.google.com/privacy?hl=de
					</a>
					.
				</p>
				<h3>SoundCloud</h3>{" "}
				<p>
					Auf dieser Website k&ouml;nnen Plugins des sozialen Netzwerks
					SoundCloud (SoundCloud Limited, Berners House, 47-48 Berners Street,
					London W1T 3NF, Gro&szlig;britannien.) integriert sein. Die
					SoundCloud-Plugins erkennen Sie an dem SoundCloud-Logo auf den
					betroffenen Seiten.
				</p>{" "}
				<p>
					Wenn Sie diese Website besuchen, wird nach Aktivierung des Plugins
					eine direkte Verbindung zwischen Ihrem Browser und dem
					SoundCloud-Server hergestellt. SoundCloud erh&auml;lt dadurch die
					Information, dass Sie mit Ihrer IP-Adresse diese Website besucht
					haben. Wenn Sie den &bdquo;Like-Button&ldquo; oder
					&bdquo;Share-Button&ldquo; anklicken, w&auml;hrend Sie in Ihrem
					SoundCloud- Benutzerkonto eingeloggt sind, k&ouml;nnen Sie die Inhalte
					dieser Website mit Ihrem SoundCloud-Profil verlinken und/oder teilen.
					Dadurch kann SoundCloud Ihrem Benutzerkonto den Besuch dieser Website
					zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten
					keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren
					Nutzung durch SoundCloud erhalten.
				</p>{" "}
				<p>
					Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
					Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
					Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den
					Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
					wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
					Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die
					Einwilligung die Speicherung von Cookies oder den Zugriff auf
					Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
					Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
					ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Gro&szlig;britannien gilt als datenschutzrechtlich sicherer
					Drittstaat. Das bedeutet, dass Gro&szlig;britannien ein
					Datenschutzniveau aufweist, das dem Datenschutzniveau in der
					Europ&auml;ischen Union entspricht.
				</p>{" "}
				<p>
					Weitere Informationen hierzu finden Sie in der
					Datenschutzerkl&auml;rung von SoundCloud unter:{" "}
					<a
						href="https://soundcloud.com/pages/privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://soundcloud.com/pages/privacy
					</a>
					.
				</p>{" "}
				<p>
					Wenn Sie nicht w&uuml;nschen, dass SoundCloud den Besuch dieser
					Website Ihrem SoundCloud- Benutzerkonto zuordnet, loggen Sie sich
					bitte aus Ihrem SoundCloud-Benutzerkonto aus bevor Sie Inhalte des
					SoundCloud-Plugins aktivieren.
				</p>
				<h3>Spotify</h3>{" "}
				<p>
					Auf dieser Website sind Funktionen des Musik-Dienstes Spotify
					eingebunden. Anbieter ist die Spotify AB, Birger Jarlsgatan 61, 113 56
					Stockholm in Schweden. Die Spotify Plugins erkennen Sie an dem
					gr&uuml;nen Logo auf dieser Website. Eine &Uuml;bersicht &uuml;ber die
					Spotify-Plugins finden Sie unter:{" "}
					<a
						href="https://developer.spotify.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://developer.spotify.com
					</a>
					.
				</p>{" "}
				<p>
					Dadurch kann beim Besuch dieser Website &uuml;ber das Plugin eine
					direkte Verbindung zwischen Ihrem Browser und dem Spotify-Server
					hergestellt werden. Spotify erh&auml;lt dadurch die Information, dass
					Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den
					Spotify Button anklicken, w&auml;hrend Sie in Ihrem Spotify-Account
					eingeloggt sind, k&ouml;nnen Sie die Inhalte dieser Website auf Ihrem
					Spotify Profil verlinken. Dadurch kann Spotify den Besuch dieser
					Website Ihrem Benutzerkonto zuordnen.
				</p>{" "}
				<p>
					Wir weisen darauf hin, dass bei der Nutzung von Spotify Cookies von
					Google Analytics eingesetzt werden, sodass Ihre Nutzungsdaten bei der
					Nutzung von Spotify auch an Google weitergegeben werden k&ouml;nnen.
					Google Analytics ist ein Tool des Google-Konzerns zur Analyse des
					Nutzerverhaltens mit Sitz in den USA. F&uuml;r diese Einbindung ist
					allein Spotify verantwortlich. Wir als Websitebetreiber haben auf
					diese Verarbeitung keinen Einfluss.
				</p>{" "}
				<p>
					Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
					Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
					Interesse an der ansprechenden akustischen Ausgestaltung seiner
					Website. Sofern eine entsprechende Einwilligung abgefragt wurde,
					erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6
					Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die
					Einwilligung die Speicherung von Cookies oder den Zugriff auf
					Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
					Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
					ist jederzeit widerrufbar.
				</p>{" "}
				<p>
					Weitere Informationen hierzu finden Sie in der
					Datenschutzerkl&auml;rung von Spotify:{" "}
					<a
						href="https://www.spotify.com/de/legal/privacy-policy/"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.spotify.com/de/legal/privacy-policy/
					</a>
					.
				</p>{" "}
				<p>
					Wenn Sie nicht w&uuml;nschen, dass Spotify den Besuch dieser Website
					Ihrem Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus
					Ihrem Spotify-Benutzerkonto aus.
				</p>
				<p>
					Quelle:{" "}
					<a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
				</p>
			</Container>
		</React.Fragment>
	);
};

export default Privacy;
