import React, { useState } from "react";
import MyModal from "./Modal";
import ContactForm from "./ContactForm";
import { Button } from "react-bootstrap";

const ContactModal = (props) => {
	const [contactModalShow, setContactModalShow] = useState(false);

	return (
		<React.Fragment>
			<Button
				className={props.classes}
				onClick={() => setContactModalShow(true)}
			>
				{props.btntitle}
			</Button>
			<MyModal
				title="Sein im Licht"
				show={contactModalShow}
				onHide={() => setContactModalShow(false)}
			>
				<ContactForm props={props.subject}/>
			</MyModal>
		</React.Fragment>
	);
};

export default ContactModal;
