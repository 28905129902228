import React, { useRef, useState } from "react";
import { Row, Col, Image, Container, Button, Carousel } from "react-bootstrap";
import Xarrow from "react-xarrows";
import useWindowDimensions from "./useWindowDimensions";

import image from "../images/3C1A8785.Schindzielorz.jpg";
import logozeichen from "../images/2022_LogoZeichen_SeinImLicht.png";
import SchoepferischeKraft from "../images/2022_SchöpferischeKraft.png";
import UrQuelle from "../images/2022_UrQuelle.png";
import UrQuelleInv from "../images/2022_UrQuelle_invert.png";

const PathToPower = () => {
	const { width } = useWindowDimensions();
	const [buttonHoverUrQuelle, setButtonHoverUrQuelle] = useState(false);
	// const topRef = useRef(null);

	const logoTop = useRef(null);
	const logoBottom = useRef(null);

	return (
		<section className="gray-background">
			<Container>
				<Row>
					<Col md={12}>
						<h2 className="display-6">Dein Weg</h2>
						<Image src={image} style={{ width: "100%" }} alt="Daniela Faber" />
						<Container>
							<div>
								<div>
									{!buttonHoverUrQuelle ? (
										<Image
											src={UrQuelleInv}
											className="img-path-to-power "
											style={{ marginTop: "45px" }}
											onMouseEnter={() => setButtonHoverUrQuelle(true)}
										/>
									) : (
										<Image
											style={{ cursor: "pointer", marginTop: "45px" }}
											src={UrQuelle}
											className="img-path-to-power"
											onMouseLeave={() => setButtonHoverUrQuelle(false)}
										/>
									)}
								</div>

								<div style={{ textAlign: "center" }}>
									<h5
										className="text-path-to-power"
										style={{ marginTop: "15px" }}
									>
										Gemeinsam finden wir deinen Weg ..
									</h5>
								</div>
								<div
									style={
										width > 991
											? { marginBottom: "120px" }
											: { marginBottom: "40px" }
									}
								>
									<Image
										src={logozeichen}
										ref={logoTop}
										className="logo-path-to-power "
									/>
								</div>
								{width > 991 ? (
									<Row>
										<Col
											id="elem1"
											style={{ zIndex: 100, padding: "6px" }}
											xs={6}
											sm={4}
											md={2}
										>
											<Button className="button-path-to-power">
												Heilung auf körperlicher Ebene
											</Button>
											<p className="sub-titel-path-to-power">Trance Healing</p>
										</Col>
										<Col
											id="elem2"
											style={{ zIndex: 100, padding: "6px" }}
											xs={6}
											sm={4}
											md={2}
										>
											<Button className="button-path-to-power">
												Heilung auf emotionaler Ebene
											</Button>
											<p className="sub-titel-path-to-power">Emotions Code</p>
										</Col>
										<Col
											id="elem3"
											style={{ zIndex: 100, padding: "6px" }}
											xs={6}
											sm={4}
											md={2}
										>
											<Button className="button-path-to-power">
												Heilung auf mentaler Ebene
											</Button>
											<p className="sub-titel-path-to-power">
												Lösen von Glaubenssätzen
											</p>
										</Col>
										<Col
											id="elem4"
											style={{ zIndex: 100, padding: "6px" }}
											xs={6}
											sm={4}
											md={2}
										>
											<Button className="button-path-to-power">
												Finde deinen inneren Frieden
											</Button>
											<p className="sub-titel-path-to-power">Meditation</p>
										</Col>
										<Col
											id="elem5"
											style={{ zIndex: 100, padding: "6px" }}
											xs={6}
											sm={4}
											md={2}
										>
											<Button className="button-path-to-power">
												Entdecke deine innere Ressource
											</Button>
											<p className="sub-titel-path-to-power">Seelenbilder</p>
										</Col>
										<Col
											id="elem6"
											style={{ zIndex: 100, padding: "6px" }}
											xs={6}
											sm={4}
											md={2}
										>
											<Button className="button-path-to-power">
												Erinnere deine Seelen-Bestimmung
											</Button>
											<p className="sub-titel-path-to-power">Kosmologie</p>
										</Col>
									</Row>
								) : (
									<Carousel id="elem7" variant="dark" className="text-center">
										<Carousel.Item>
											<Col style={{ zIndex: 100, padding: "20px" }}>
												<div
													style={{
														border: "1px solid",
														borderRadius: "5px",
														padding: "20px",
														marginBottom: "20px",
														boxShadow: "2px 2px 16px rgba(92, 92, 92, 0.15)",
														width: "80%",
														marginRight: "auto",
														marginLeft: "auto",
													}}
												>
													<Button>Heilung auf körperlicher Ebene</Button>
													<p className="sub-titel-path-to-power-card">
														Trance Healing
													</p>
												</div>
											</Col>
										</Carousel.Item>
										<Carousel.Item>
											<Col style={{ zIndex: 100, padding: "20px" }}>
												<div
													style={{
														border: "1px solid",
														borderRadius: "5px",
														padding: "20px",
														marginBottom: "20px",
														boxShadow: "2px 2px 16px rgba(92, 92, 92, 0.15)",
														width: "80%",
														marginRight: "auto",
														marginLeft: "auto",
													}}
												>
													<Button>Heilung auf emotionaler Ebene</Button>
													<p className="sub-titel-path-to-power-card">
														Emotions Code
													</p>
												</div>
											</Col>
										</Carousel.Item>
										<Carousel.Item>
											<Col style={{ zIndex: 100, padding: "20px" }}>
												<div
													style={{
														border: "1px solid",
														borderRadius: "5px",
														padding: "20px",
														marginBottom: "20px",
														boxShadow: "2px 2px 16px rgba(92, 92, 92, 0.15)",
														width: "80%",
														marginRight: "auto",
														marginLeft: "auto",
													}}
												>
													<Button>Heilung auf mentaler Ebene</Button>
													<p className="sub-titel-path-to-power-card">
														Lösen von Glaubenssätzen
													</p>
												</div>
											</Col>
										</Carousel.Item>
										<Carousel.Item>
											<Col style={{ zIndex: 100, padding: "20px" }}>
												<div
													style={{
														border: "1px solid",
														borderRadius: "5px",
														padding: "20px",
														marginBottom: "20px",
														boxShadow: "2px 2px 16px rgba(92, 92, 92, 0.15)",
														width: "80%",
														marginRight: "auto",
														marginLeft: "auto",
													}}
												>
													<Button>Finde deinen inneren Frieden</Button>
													<p className="sub-titel-path-to-power-card">
														Meditation
													</p>
												</div>
											</Col>
										</Carousel.Item>
										<Carousel.Item>
											<Col style={{ zIndex: 100, padding: "20px" }}>
												<div
													style={{
														border: "1px solid",
														borderRadius: "5px",
														padding: "20px",
														marginBottom: "20px",
														boxShadow: "2px 2px 16px rgba(92, 92, 92, 0.15)",
														width: "80%",
														marginRight: "auto",
														marginLeft: "auto",
													}}
												>
													<Button>Entdecke deine innere Ressource</Button>
													<p className="sub-titel-path-to-power-card">
														Seelenbilder
													</p>
												</div>
											</Col>
										</Carousel.Item>
										<Carousel.Item>
											<Col style={{ zIndex: 100, padding: "20px" }}>
												<div
													style={{
														border: "1px solid",
														borderRadius: "5px",
														padding: "20px",
														marginBottom: "20px",
														boxShadow: "2px 2px 16px rgba(92, 92, 92, 0.15)",
														width: "80%",
														marginRight: "auto",
														marginLeft: "auto",
													}}
												>
													<Button>Erinnere deine Seelen-Bestimmung</Button>
													<p className="sub-titel-path-to-power-card">
														Kosmologie
													</p>
												</div>
											</Col>
										</Carousel.Item>
									</Carousel>
								)}

								<div
									style={
										width > 991 ? { marginTop: "120px" } : { marginTop: "40px" }
									}
								>
									<Image
										src={logozeichen}
										className="logo-path-to-power "
										ref={logoBottom}
									/>
								</div>
								<div style={{ textAlign: "center" }}>
									<h5 className="text-path-to-power">
										.. in deine schöpferische Kraft
									</h5>
								</div>
								<Image
									src={SchoepferischeKraft}
									className="img-path-to-power "
								/>
								{width > 991 ? (
									<React.Fragment>
										<Xarrow
											start={logoTop}
											startAnchor="bottom"
											endAnchor="top"
											end="elem1"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
										/>
										<Xarrow
											start={logoTop}
											startAnchor="bottom"
											endAnchor="top"
											end="elem2"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
										/>
										<Xarrow
											start={logoTop}
											startAnchor="bottom"
											endAnchor="top"
											end="elem3"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
											showXarrow={width < 576 ? false : true}
										/>
										<Xarrow
											start={logoTop}
											startAnchor="bottom"
											endAnchor="top"
											end="elem4"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
											showXarrow={width <= 767 ? false : true}
										/>
										<Xarrow
											start={logoTop}
											startAnchor="bottom"
											endAnchor="top"
											end="elem5"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
											showXarrow={width <= 767 ? false : true}
										/>
										<Xarrow
											start={logoTop}
											startAnchor="bottom"
											endAnchor="top"
											end="elem6"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
											showXarrow={width <= 767 ? false : true}
										/>

										<Xarrow
											end={logoBottom}
											startAnchor="bottom"
											endAnchor="top"
											start="elem1"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
											showXarrow={width <= 767 ? false : true}
										/>

										<Xarrow
											end={logoBottom}
											startAnchor="bottom"
											endAnchor="top"
											start="elem2"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
											showXarrow={width <= 767 ? false : true}
										/>
										<Xarrow
											end={logoBottom}
											startAnchor="bottom"
											endAnchor="top"
											start="elem3"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
											showXarrow={width <= 767 ? false : true}
										/>
										<Xarrow
											end={logoBottom}
											startAnchor="bottom"
											endAnchor="top"
											start="elem4"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
											showXarrow={width < 576 ? false : true}
										/>
										<Xarrow
											end={logoBottom}
											startAnchor="bottom"
											endAnchor="top"
											start="elem5"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
										/>
										<Xarrow
											end={logoBottom}
											startAnchor="bottom"
											endAnchor="top"
											start="elem6"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
										/>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Xarrow
											start={logoTop}
											startAnchor="bottom"
											endAnchor="top"
											end="elem7"
											color="#d4b66b"
											showHead={false}
											curveness={0.8}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
										/>
										<Xarrow
											end={logoBottom}
											startAnchor="bottom"
											endAnchor="top"
											start="elem7"
											color="#d4b66b"
											showHead={false}
											curveness={0}
											strokeWidth={2}
											zIndex={1}
											animateDrawing={1}
										/>
									</React.Fragment>
								)}
							</div>
						</Container>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PathToPower;
